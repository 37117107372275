import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBack, SearchBar } from '../../components';
import {
  generateGasketList,
  filterGaskets,
  fetchGasketAmount,
} from './gasket-overview-logic';
import GasketParamsDesktop from './components/GasketParamsDesktop';
import GasketParamsMobile from './components/GasketParamsMobile';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { fetchGasketParams } from './components/gasket-params-logic';

const GasketOverview = () => {
  const testName = decodeURIComponent(
    useLocation().pathname.split('/')[2]
  );
  const [initialState, setInitialState] = useState({});
  const [gasketList, setGasketList] = useState([]);
  const [search, setSearch] = useState('');
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [initialParams, setInitialParams] = useState({
    gasketType: '',
    inspType: '',
    testGas: '',
    vHood: '',
    minConcRaise: '',
    maxAllowedLeak: '',
    stemTime: localStorage.getItem('stemTime') || '',
  });

  const id = useRef(null);

  useEffect(() => {
    const fetchGaskets = async () => {
      try {
        const { amount, gasketListWithParams } =
          await fetchGasketAmount(testName);

        setInitialState({
          amount: amount,
          gasketListWithParams: gasketListWithParams,
        });
      } catch (err) {
        navigate('/dashboard');
      }
    };

    fetchGaskets();

    window.addEventListener('update-gaskets', fetchGaskets);

    return () =>
      window.removeEventListener(
        'update-gaskets',
        fetchGaskets
      );
  }, [testName, navigate]);

  useEffect(() => {
    const handleParams = async newId => {
      id.current = newId;

      const data = await fetchGasketParams(id, testName);

      setInitialParams({
        gasketType: data?.name || '',
        inspType: data?.inspectionType || '',
        testGas: data?.testGas || 100,
        vHood: data?.vHood || '',
        minConcRaise:
          data?.minConcRaise ||
          localStorage.getItem('minConcRaise') ||
          '',
        maxAllowedLeak:
          (data?.maxAllowedLeak &&
            data.maxAllowedLeak.toExponential(1)) ||
          localStorage.getItem('maxAllowedLeak') ||
          '',
        stemTime:
          data?.stemTime ||
          localStorage.getItem('stemTime') ||
          '',
      });
    };

    window.addEventListener('params-update', e =>
      handleParams(e.detail)
    );

    return () =>
      window.removeEventListener('params-update', e =>
        handleParams(e.detail)
      );

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleParams = async newId => {
      id.current = newId;

      const data = await fetchGasketParams(id, testName);

      setInitialParams({
        gasketType: data?.name || '',
        inspType: data?.inspectionType || '',
        testGas: data?.testGas | 100,
        vHood: data?.vHood || '',
        minConcRaise: data?.minConcRaise || '',
        maxAllowedLeak:
          (data?.maxAllowedLeak &&
            data.maxAllowedLeak.toExponential(1)) ||
          '',
        stemTime: data?.stemTime || '',
      });

      setOpen(true);
    };

    const handleTest = id => navigate(`./${id}`);

    generateGasketList(
      initialState,
      setGasketList,
      handleParams,
      handleTest
    );

    // eslint-disable-next-line
  }, [initialState, width, navigate]);

  useEffect(() => {
    const fetchGaskets = async () => {
      try {
        const { amount, gasketListWithParams } =
          await fetchGasketAmount(testName);

        setInitialState({
          amount: amount,
          gasketListWithParams: gasketListWithParams,
        });
      } catch (err) {
        navigate('/dashboard');
      }
    };

    const interval = setInterval(fetchGaskets, 10000);

    return () => clearInterval(interval);
  }, [testName, navigate]);

  return (
    <>
      {width >= 768 ? (
        <GasketParamsDesktop
          open={open}
          setOpen={setOpen}
          testName={testName}
          id={id}
          offset={initialState?.amount - gasketList.length}
          amount={initialState?.amount}
          initialParams={initialParams}
          setInitialParams={setInitialParams}
        />
      ) : (
        <GasketParamsMobile
          open={open}
          setOpen={setOpen}
          testName={testName}
          id={id}
          offset={initialState?.amount - gasketList.length}
          amount={initialState?.amount}
          initialParams={initialParams}
          setInitialParams={setInitialParams}
        />
      )}
      <div className="absolute top-2 left-4 md:left-40">
        <ArrowBack
          path="/dashboard"
          label="Back to tests"
        />
      </div>

      <div className="flex h-full w-full flex-col items-center overflow-y-hidden pt-16 pb-14 md:pb-0">
        <p className="select-none text-center font-heading text-2xl font-light md:text-4xl">
          {testName}
          <br />
          <span className="block text-sm font-extralight opacity-70 md:text-lg">
            Test the gaskets here
          </span>
        </p>
        <div className="flex h-full w-full justify-center pt-6 md:pt-10">
          <div className="relative h-full w-5/6 md:w-3/5">
            <p className="w-full select-none border-b border-white/10 pb-3 font-heading text-xl md:text-2xl">
              Todo
            </p>
            <div className="absolute right-0 -top-1 w-full md:w-1/3">
              <SearchBar
                value={search}
                setValue={setSearch}
              />
            </div>
            <div className="mt-6 max-h-[66%] w-full grid-cols-3 gap-4 overflow-y-auto pt-0.5 md:grid">
              {gasketList.length !== 0 ? (
                filterGaskets(gasketList, search)
              ) : (
                <span className="font-heading text-sm font-extralight opacity-70 md:whitespace-nowrap">
                  Looks like you finished all gasket tests!
                  🚀
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GasketOverview;
