const Card = ({
  size,
  color,
  title,
  leftSection,
  rightSection,
  rightIcon,
  onClick,
  disabled,
}) => {
  return (
    <div className="h-auto w-full">
      {title && (
        <p className="mb-1 select-none font-heading text-sm font-extralight text-white/50">
          {title}
        </p>
      )}
      <div
        className={`${size} flex h-full w-full items-center space-x-2`}>
        <div
          className={`flex h-full w-full items-center rounded-lg border border-[#292B2F] p-2 transition-all ${
            disabled
              ? 'select-none opacity-50'
              : 'cursor-pointer hover:bg-[#131415] active:-translate-y-0.5'
          }`}
          onClick={onClick}>
          <div
            className={`${
              disabled ? 'bg-red-400' : color
            } mr-3 h-full w-0.5 rounded-full`}
          />
          <div className="flex h-full w-full items-center justify-between pr-2">
            {leftSection}
            {rightSection}
          </div>
        </div>
        <div className="grid h-full place-items-center">
          {rightIcon}
        </div>
      </div>
    </div>
  );
};

export default Card;
