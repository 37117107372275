import { toast } from 'react-toastify';

const getPdfReport = async testName => {
  const response = await fetch(
    `${process.env.REACT_APP_DOMAIN}/api/test/pdf-report`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      mode: 'cors',
      body: JSON.stringify({
        name: testName,
      }),
    }
  );

  if (response.status !== 200) {
    const json = await response.json();

    return toast.error(json?.error);
  }

  const blob = await response.blob();
  const file = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  const date = new Date().toLocaleDateString();

  link.href = file;
  link.download = `${testName} - ${date}.pdf`;
  link.click();
};

export default getPdfReport;
