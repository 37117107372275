import {
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  ArrowBack,
  SearchBar,
  Card,
} from '../../components';
import {
  MdPictureAsPdf,
  MdDateRange,
  MdOpacity,
} from 'react-icons/md';
import { useEffect, useState } from 'react';
import { fetchGasketAmount } from '../GasketOverview/gasket-overview-logic';
import { searchTermMatches } from '../../helper/utils';
import {
  getPdfReport,
  pdfDownloaded,
} from '../../api/tests';

const GasketResults = () => {
  const testName = decodeURIComponent(
    useLocation().pathname.split('/')[2]
  );
  const navigate = useNavigate();

  const [finishedGaskets, setFinishedGaskets] = useState(
    []
  );
  const [search, setSearch] = useState('');
  const [hasPdf, setHasPdf] = useState(null);
  const [searchParams] = useSearchParams();
  const completed = searchParams.get('completed');

  useEffect(() => {
    if (completed === null) navigate('/dashboard');

    try {
      fetchGasketAmount(testName).then(list => {
        if (!list) navigate('/dashboard');

        const data = list?.gasketListWithParams?.filter(
          gasket => gasket?.completed
        );

        setFinishedGaskets(data);
      });
    } catch (error) {
      navigate('/dashboard');
    }
  }, [navigate, testName, completed]);

  useEffect(() => {
    pdfDownloaded(testName).then(value => {
      if (
        value?.pdfDownloaded &&
        value?.pdfDownloaded?.completed === 1
      ) {
        setHasPdf(value?.pdfDownloaded?.pdfDownloaded);
      }
    });
  }, [testName]);

  return (
    <>
      <div
        className={`absolute top-2 left-4 ${
          completed === 'false' ? 'md:left-40' : ''
        }`}>
        <ArrowBack
          path="/dashboard"
          label="Back to tests"
        />
      </div>

      <div className="flex h-full w-full flex-col items-center overflow-y-hidden pt-16 pb-14 md:pb-0">
        <p className="select-none text-center font-heading text-2xl font-light md:text-4xl">
          {testName}
          <br />
          <span className="block text-sm font-extralight opacity-70 md:text-lg">
            View the gasket results here
          </span>
        </p>
        {finishedGaskets?.length !== 0 && (
          <span
            className="absolute right-[8.33%] mt-2 grid translate-x-[8.33%] cursor-pointer place-items-center rounded-full fill-red-400 p-1.5 transition-all hover:bg-white/10 active:translate-y-0.5 md:right-[20%] md:translate-x-[20%] md:p-2"
            onClick={() => {
              getPdfReport(testName).then(() => {
                setHasPdf(true);
                setFinishedGaskets(
                  finishedGaskets.map(value => ({
                    ...value,
                    pdfDownloaded: 1,
                  }))
                );
              });
            }}>
            <MdPictureAsPdf
              className={`h-6 w-6 md:h-8 md:w-8 ${
                !hasPdf ? 'fill-red-400' : 'fill-green-400'
              }`}
            />
            {!hasPdf && (
              <span className="absolute top-1 right-0 grid h-2 w-2 place-items-center rounded-full bg-red-400">
                <span className="h-full w-full animate-ping rounded-full bg-red-500" />
              </span>
            )}
          </span>
        )}
        <div className="flex h-full w-full justify-center pt-6 md:pt-10">
          <div className="relative h-full w-5/6 md:w-3/5">
            <p className="w-full select-none border-b border-white/10 pb-3 font-heading text-xl md:text-2xl">
              Gasket results
            </p>
            <div className="absolute right-0 -top-1 w-full md:w-1/3">
              <SearchBar
                value={search}
                setValue={setSearch}
              />
            </div>
            <div className="mt-6 max-h-[66%] w-full gap-4 overflow-y-auto pt-0.5 md:grid md:grid-cols-2 lg:grid-cols-3">
              {finishedGaskets?.length !== 0 ? (
                finishedGaskets?.map((gasket, index) => {
                  if (
                    searchTermMatches(
                      gasket.name,
                      search
                    ) ||
                    searchTermMatches(gasket.author, search)
                  )
                    return (
                      <div
                        key={index}
                        className="w-auto pb-4 pr-2 md:pb-0"
                        onClick={() =>
                          navigate(
                            `/dashboard/${testName}/${gasket.id}/result?completed=${completed}`
                          )
                        }>
                        <Card
                          size="h-24 md:h-28"
                          color="bg-[#51F47F]"
                          leftSection={
                            <div className="text-heading flex h-full select-none flex-col justify-between text-sm leading-snug md:leading-normal">
                              <span className="font-bold">
                                {gasket.name}
                              </span>
                              <span className="opacity-70">
                                {gasket.author}
                              </span>
                              <div className="flex items-center space-x-2">
                                <MdOpacity className="h-3 w-3 opacity-50" />
                                <span>
                                  {gasket.finalLeakValue ===
                                  0
                                    ? gasket.finalLeakValue
                                    : gasket.inspectionType ===
                                      'Concentration Sniffing'
                                    ? (
                                        gasket.finalLeakValue *
                                        1e-6
                                      ).toExponential(1)
                                    : gasket.finalLeakValue.toExponential(
                                        1
                                      )}
                                </span>
                                <span className="font-heading text-sm font-thin opacity-70">
                                  {gasket.inspectionType ===
                                  'Concentration Sniffing'
                                    ? 'ppm'
                                    : 'mbar.l/s'}
                                </span>
                              </div>
                              <div className="flex items-center space-x-2">
                                <MdDateRange className="h-3 w-3 opacity-50" />
                                <p>
                                  {gasket.endDate}{' '}
                                  {gasket.endTime.slice(
                                    0,
                                    -3
                                  )}
                                </p>
                              </div>
                            </div>
                          }
                          rightSection={
                            <MdPictureAsPdf
                              className={`h-6 w-6 ${
                                gasket.pdfDownloaded
                                  ? 'fill-[#51F47F]'
                                  : 'fill-[#D85656]'
                              }`}
                            />
                          }
                        />
                      </div>
                    );
                  return null;
                })
              ) : (
                <span className="font-heading text-sm font-extralight opacity-70">
                  No gaskets finished yet!
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GasketResults;
