import {
  useContext,
  useRef,
  useEffect,
  useState,
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  NumericInput,
  Button,
  ResultViewContainer,
} from '../../../../components';
import { StepperContext } from '../../../../context/StepperContext';
import {
  MdCalculate,
  MdOpacity,
  MdBubbleChart,
  MdDateRange,
  MdAccessTime,
  MdTimelapse,
} from 'react-icons/md';
import { ReactComponent as TestingIcon } from '../../../../assets/svg/testing.svg';
import {
  validate,
  getDynamicProps,
  handleSubmit,
  handleFirstStep,
  calculateDiffTime,
} from './testing-step-logic';
import { FaRedo } from 'react-icons/fa';
import { timeToSeconds } from '../../../../helper/utils';

const { Result, ResultViewbox } = ResultViewContainer;

const TestingStep = ({
  testName,
  params,
  state,
  setState,
  calibration,
  testCounter,
  setTestCounter,
  user,
  isSecondReading = false,
}) => {
  const { title } = getDynamicProps(params.inspectionType);
  const { stepState } = useContext(StepperContext);
  const [stepIndex, setStepIndex] = stepState;
  const [newStemTime, setNewStemTime] = useState('');
  const { gasket } = useParams();
  const navigate = useNavigate();
  const inputRef = useRef();

  useEffect(() => {
    let interval;

    if (isSecondReading) {
      interval = setInterval(
        () =>
          setTestCounter(
            calculateDiffTime(
              state.startTime,
              state.startDate
            )
          ),
        1000
      );
    }

    return () => clearInterval(interval);
  }, [
    isSecondReading,
    state.startTime,
    state.startDate,
    setTestCounter,
  ]);

  if (!calibration) return null;

  const {
    sensitivity,
    author,
    endDate,
    endTime,
    readingRefLeak,
    refValue,
    readingBg,
  } = calibration;

  const disabled = validate(
    params.inspectionType,
    state.readingLeak1,
    state.readingLeak2,
    isSecondReading
  );

  const submitOptions = {
    inspType: params.inspectionType,
    readingLeak1: state.readingLeak1,
    readingLeak2: state.readingLeak2,
    sensitivity: sensitivity,
    vHood: params.vHood,
    deltaTime:
      newStemTime === '' ? testCounter : newStemTime,
    testGas: params.testGas,
    id: gasket,
    refValue: refValue,
    user: user,
    testName: testName,
    navigate: navigate,
    sensAuthor: author,
    sensDate: endDate,
    sensTime: endTime,
    sensReadingRefLeak: readingRefLeak,
    sensReadingBg: readingBg,
  };

  const firstStepOptions = {
    readingLeak1: state.readingLeak1,
    id: gasket,
    testName,
    setStepIndex,
    setState,
  };

  return (
    <div className="relative flex h-full">
      <div className="flex h-full w-full flex-col items-center space-y-5 pt-5 font-text md:space-y-10 md:pt-10">
        <h1 className="select-none text-center md:text-2xl">
          Calculate the leakrate
          <br />
          <span className="hidden font-heading opacity-70 md:inline-block md:text-sm">
            Test the selected flange here
          </span>
        </h1>
        <div className="flex w-full items-center justify-center space-x-10">
          <div className="flex flex-col space-y-4">
            {params.inspectionType === 'Accumulation' &&
              isSecondReading &&
              state.startTime !== '' && (
                <>
                  <ResultViewbox label="Stemtime">
                    <Result
                      leftIcon={
                        <MdDateRange className="h-6 w-6 opacity-70" />
                      }
                      text={params.stemTime}
                      rightSection="-"
                    />
                    <Result
                      leftIcon={
                        <MdAccessTime className="h-6 w-6 opacity-70" />
                      }
                      text={testCounter}
                      error={
                        timeToSeconds(testCounter) <=
                        timeToSeconds(params.stemTime)
                      }
                      rightSection="-"
                    />
                  </ResultViewbox>
                  <NumericInput
                    label="Stemtime Override"
                    leftIcon={
                      <MdTimelapse className="h-6 w-6 opacity-70" />
                    }
                    rightSection="-"
                    type="time"
                    step="1"
                    value={newStemTime}
                    handler={e =>
                      setNewStemTime(e.target.value)
                    }
                  />
                </>
              )}
            {params.inspectionType === 'Accumulation' &&
            state.startTime !== '' &&
            !isSecondReading ? (
              <ResultViewbox label="Reading 1">
                <Result
                  leftIcon={
                    <MdBubbleChart className="h-6 w-6 opacity-70" />
                  }
                  text={parseFloat(
                    state.readingLeak1
                  ).toExponential(1)}
                  rightSection={'-'}
                />
                <Result
                  leftIcon={
                    <MdDateRange className="h-6 w-6 opacity-70" />
                  }
                  text={state.startDate}
                  rightSection={state.startTime}
                />
              </ResultViewbox>
            ) : (
              <NumericInput
                label={
                  isSecondReading ? 'Reading 2' : title
                }
                rightSection="-"
                autoFocus
                leftIcon={
                  <MdBubbleChart className="h-6 w-6" />
                }
                value={
                  params.inspectionType ===
                    'Accumulation' && isSecondReading
                    ? state.readingLeak2
                    : state.readingLeak1
                }
                handler={e =>
                  setState(prev => {
                    if (
                      params.inspectionType ===
                        'Accumulation' &&
                      isSecondReading
                    )
                      return {
                        ...prev,
                        readingLeak2: e.target.value,
                      };
                    else
                      return {
                        ...prev,
                        readingLeak1: e.target.value,
                      };
                  })
                }
                placeholder="Number"
                enterKeyHint="continue"
                onKeyDown={e => {
                  if (
                    e.key === 'Enter' &&
                    e.target.value !== ''
                  ) {
                    if (
                      params.inspectionType ===
                      'Accumulation'
                    ) {
                      if (!isSecondReading && !disabled) {
                        handleFirstStep(firstStepOptions);
                        e.target.blur();
                      } else if (
                        isSecondReading &&
                        !disabled
                      ) {
                        handleSubmit(submitOptions);
                        e.target.blur();
                      }
                    } else inputRef.current.focus();
                  }
                }}
              />
            )}
            {params.inspectionType !== 'Accumulation' && (
              <NumericInput
                currRef={inputRef}
                autoFocus={isSecondReading}
                label="Detector reading leak"
                rightSection="-"
                placeholder="Number"
                enterKeyHint="go"
                leftIcon={<MdOpacity className="h-6 w-6" />}
                value={state.readingLeak2}
                handler={e =>
                  setState(prev => ({
                    ...prev,
                    readingLeak2: e.target.value,
                  }))
                }
                onKeyDown={e => {
                  if (e.key === 'Enter' && !disabled) {
                    handleSubmit(submitOptions);
                    e.target.blur();
                  }
                }}
              />
            )}
          </div>
          <TestingIcon className="hidden max-h-[250px] max-w-xs md:inline-flex" />
        </div>
      </div>
      {params.inspectionType === 'Accumulation' &&
        !isSecondReading &&
        state.startTime !== '' && (
          <div className="absolute bottom-24 left-1/2 w-full max-w-lg -translate-x-1/2 md:bottom-36">
            <Button
              text="Recalculate"
              variant="outline"
              icon={<FaRedo className="h-4 w-4" />}
              onClick={() => {
                setState(prev => ({
                  ...prev,
                  startTime: '',
                  startDate: '',
                }));

                setTestCounter('00:00:00');
              }}
            />
          </div>
        )}
      <div className="absolute bottom-4 left-1/2 flex w-full max-w-lg -translate-x-1/2 items-center justify-between space-x-4 md:bottom-20">
        {((params.inspectionType === 'Accumulation' &&
          state.startTime === '') ||
          params.inspectionType !== 'Accumulation' ||
          isSecondReading) && (
          <Button
            text={
              params.inspectionType === 'Accumulation'
                ? !isSecondReading
                  ? 'Confirm'
                  : 'Calculate'
                : 'Calculate'
            }
            variant="outline"
            icon={<MdCalculate className="h-6 w-6" />}
            disabled={disabled}
            onClick={
              params.inspectionType === 'Accumulation'
                ? isSecondReading
                  ? () => handleSubmit(submitOptions)
                  : () => handleFirstStep(firstStepOptions)
                : () => handleSubmit(submitOptions)
            }
          />
        )}
        {params.inspectionType === 'Accumulation' &&
        state.startTime !== '' &&
        !isSecondReading ? (
          <Button
            text="Next"
            onClick={() => setStepIndex(prev => prev + 1)}
          />
        ) : (
          state.startTime !== '' &&
          params.inspectionType === 'Accumulation'
        )}
        <Button
          text="Back"
          variant="subtle"
          onClick={() => setStepIndex(stepIndex - 1)}
        />
      </div>
    </div>
  );
};

export default TestingStep;
