import { MdOutlineAdd } from 'react-icons/md';
import { ReactComponent as Logo } from '../assets/svg/logo.svg';

const SideBarDesktop = ({
  showFooter,
  footerBounce,
  footerAction,
  navbar,
}) => {
  return (
    <div
      className={`fixed top-0 left-0 hidden h-full w-36 flex-col items-center justify-between border-r border-[#2F3036] will-change-transform md:flex`}>
      <div className="mt-6">
        <Logo />
      </div>
      {navbar}
      {showFooter && (
        <div className="mb-6 w-4/5 border-t border-[#2F3036] pt-6">
          <div
            className={`flex h-10 w-full cursor-pointer items-center justify-between rounded-lg bg-[#252A34] pl-2 pr-3 transition-all active:-translate-y-0.5 active:bg-[#414753] ${
              footerBounce && 'animate-bounce'
            }`}
            onClick={footerAction}>
            <MdOutlineAdd className="h-7 w-7 fill-white" />
            <p className="select-none font-text text-sm">
              Add Test
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SideBarDesktop;
