import { useState, createContext } from 'react';

export const TabsContext = createContext();

export const TabsProvider = ({ children }) => {
  const [tabIndex, setTabIndex] = useState(1);

  return (
    <TabsContext.Provider value={[tabIndex, setTabIndex]}>
      {children}
    </TabsContext.Provider>
  );
};
