import { useContext, useRef } from 'react';
import {
  Drawer,
  Stepper,
  Button,
} from '../../../components';
import { StepperContext } from '../../../context/StepperContext';
import {
  MdDriveFileRenameOutline,
  MdTune,
  MdList,
  MdOutlineAccessTime,
} from 'react-icons/md';
import {
  generateInput,
  handleSubmit,
} from './gasket-params-logic';

const Step = ({
  state,
  setState,
  setOpen,
  id,
  testName,
  length,
  offset,
}) => {
  const { stepState } = useContext(StepperContext);
  const [stepIndex, setStepIndex] = stepState;

  const refs = {
    testGas: useRef(),
    vHood: useRef(),
    maxAllowedLeak: useRef(),
  };

  const { component, disabled } = generateInput(
    stepIndex,
    state,
    setState,
    refs,
    setStepIndex,
    setOpen,
    id,
    testName,
    length
  );

  return (
    <div className="flex flex-col justify-between space-y-8 py-4">
      {component}
      <div className="flex w-4/5 items-center justify-between space-x-4">
        <Button
          text={
            (stepIndex !== 4 &&
              state.inspType === 'Accumulation') ||
            (stepIndex !== 3 &&
              state.inspType !== 'Accumulation')
              ? 'Continue'
              : 'Confirm'
          }
          disabled={disabled}
          onClick={() => {
            if (
              (stepIndex !== 4 &&
                state.inspType === 'Accumulation') ||
              (stepIndex !== 3 &&
                state.inspType !== 'Accumulation')
            )
              setStepIndex(stepIndex + 1);
            else
              handleSubmit(
                state,
                setState,
                setOpen,
                id,
                testName,
                setStepIndex,
                length
              );
          }}
        />
        <div className="h-full w-1/2">
          <Button
            text="Back"
            variant="subtle"
            disabled={id?.current - offset === 1}
            onClick={() => {
              if (stepIndex > 1)
                setStepIndex(stepIndex - 1);
              else if (
                id?.current &&
                id.current - offset > 1
              )
                window.dispatchEvent(
                  new CustomEvent('params-update', {
                    detail: id.current - 1,
                  })
                );
            }}
          />
        </div>
      </div>
    </div>
  );
};
const GasketParamsMobile = ({
  open,
  setOpen,
  id,
  testName,
  initialParams,
  setInitialParams,
  amount,
  offset,
}) => {
  return (
    <Drawer
      title="Insert parameters"
      open={open}
      setOpen={() => setOpen(false)}
      height="h-full">
      <label
        htmlFor="progress"
        className="font-heading text-sm text-white/70">
        Gasket {id?.current} - {amount}
      </label>
      <div
        id="progress"
        className="relative mt-2 mb-3 h-0.5 w-full rounded-full bg-gray-500 md:mb-0">
        <div
          className="h-full w-full origin-left rounded-l-full bg-blue-400 transition-transform"
          style={{
            transform: `scaleX(${
              id?.current ? (id?.current / amount) * 100 : 0
            }%)`,
            borderTopRightRadius:
              (id?.current / amount) * 100 === 100
                ? '9999px'
                : 0,
            borderBottomRightRadius:
              (id?.current / amount) * 100 === 100
                ? '9999px'
                : 0,
          }}
        />
      </div>
      <Stepper
        length={
          initialParams.inspType === 'Accumulation' ? 4 : 3
        }>
        <Stepper.Step
          title="Gasket type"
          label="Insert the gasket type you wish to inspect"
          icon={
            <MdDriveFileRenameOutline className="h-full w-full" />
          }>
          <Step
            state={initialParams}
            setState={setInitialParams}
            id={id}
            testName={testName}
            length={amount}
            offset={offset}
          />
        </Stepper.Step>
        <Stepper.Step
          title="Inspection method"
          label="Choose between the four inspection methods"
          icon={<MdTune className="h-full w-full" />}>
          <Step
            state={initialParams}
            setState={setInitialParams}
            id={id}
            testName={testName}
          />
        </Stepper.Step>
        <Stepper.Step
          title="Testing parameters"
          label="Insert the necessary calibration and testing parameters here"
          icon={<MdList className="h-full w-full" />}>
          <Step
            state={initialParams}
            setState={setInitialParams}
            setOpen={setOpen}
            id={id}
            testName={testName}
            length={amount}
          />
        </Stepper.Step>
        {initialParams.inspType === 'Accumulation' ? (
          <Stepper.Step
            title="Stem Time"
            label="Insert or calculate the stem time here"
            icon={
              <MdOutlineAccessTime className="h-full w-full" />
            }>
            <Step
              state={initialParams}
              setState={setInitialParams}
              setOpen={setOpen}
              id={id}
              testName={testName}
              length={amount}
            />
          </Stepper.Step>
        ) : (
          <></>
        )}
      </Stepper>
    </Drawer>
  );
};

export default GasketParamsMobile;
