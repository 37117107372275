import { createPortal } from 'react-dom';
import { useEffect, useRef, useState } from 'react';
import { MdClose } from 'react-icons/md';
import useOnClickOutside from '../hooks/useOnClickOutside';

const Modal = ({
  title,
  size,
  rounded,
  canClose = true,
  open,
  setOpen,
  children,
}) => {
  const [mounted, setMounted] = useState(false);
  const modalRef = useRef();

  useOnClickOutside(modalRef, setOpen);

  useEffect(() => {
    let timeout;

    if (!open)
      timeout = setTimeout(() => setMounted(false), 150);
    else setMounted(true);

    return () => clearTimeout(timeout);
  }, [open]);

  if (!open && !mounted) return null;

  return createPortal(
    <div className="fixed w-full h-full z-50 grid place-items-center">
      <div className="w-full h-full absolute backdrop-brightness-50 bg-opacity-10 z-0" />
      <div
        ref={modalRef}
        className={`bg-[#252A34] ${
          open ? 'animate-popUp' : 'animate-popOut'
        }
         z-10 drop-shadow-lg px-3 py-2 ${size} ${rounded}`}>
        <div className="w-full border-b border-white/10 flex items-center justify-between text-white overflow-y-auto pb-1 mb-2">
          <p
            className={`font-heading text-xl select-none ${
              !canClose && 'w-full text-center'
            }`}>
            {title}
          </p>
          {canClose && (
            <MdClose
              className="fill-white/70 p-2 h-8 w-8 rounded-full transition-all hover:bg-white/10 hover:fill-white active:translate-y-0.5 cursor-pointer"
              onClick={setOpen}
            />
          )}
        </div>
        {children}
      </div>
    </div>,
    document.getElementById('modal-portal')
  );
};

export default Modal;
