import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button } from '../../components';
import {
  MdAccountCircle,
  MdLock,
  MdEmail,
  MdVpnKey,
} from 'react-icons/md';
import { ReactComponent as Logo } from '../../assets/svg/logo.svg';
import { handleSubmit } from './register-logic';

const Register = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authToken, setAuthToken] = useState('');

  const ref = {
    email: useRef(),
    password: useRef(),
    authToken: useRef(),
  };

  const [error, setError] = useState({
    username: null,
    email: null,
    password: null,
    authToken: null,
  });

  return (
    <div className="flex h-full w-full flex-col items-center justify-between pt-6 pb-10">
      {/* Header Text + Logo */}
      <div className="flex items-center">
        <Logo />
        <hr className="ml-7 h-10 w-px rounded-full border-none bg-white/50" />
        <p className="select-none pl-4 font-heading text-white/70">
          ITIS Cloud Testing
        </p>
      </div>
      <div className="space-y-10 md:flex md:h-1/2 md:flex-col md:justify-between">
        <p className="select-none text-center font-heading text-2xl font-light md:text-4xl">
          Create new account <br />
          <span className="block text-sm font-extralight opacity-70 md:text-lg">
            Please fill in the form to continue
          </span>
        </p>
        {/* Form */}
        <div>
          <div className="space-y-6">
            <Input
              placeholder="Username"
              invalid={error.username}
              autoFocus
              value={username}
              handler={e => setUsername(e.target.value)}
              enterKeyHint="next"
              onKeyDown={e => {
                if (e.key === 'Enter')
                  ref.email.current.focus();
              }}
              leftIcon={
                <MdAccountCircle className="h-5 w-5 fill-white" />
              }
            />
            <Input
              currentRef={ref.email}
              placeholder="Email"
              invalid={error.email}
              value={email}
              handler={e => setEmail(e.target.value)}
              enterKeyHint="next"
              onKeyDown={e => {
                if (e.key === 'Enter')
                  ref.password.current.focus();
              }}
              leftIcon={
                <MdEmail className="h-5 w-5 fill-white" />
              }
            />
            <Input
              currentRef={ref.password}
              type="password"
              placeholder="Password"
              invalid={error.password}
              value={password}
              handler={e => setPassword(e.target.value)}
              enterKeyHint="next"
              onKeyDown={e => {
                if (e.key === 'Enter')
                  ref.authToken.current.focus();
              }}
              leftIcon={
                <MdLock className="h-5 w-5 fill-white" />
              }
            />
            <Input
              currentRef={ref.authToken}
              placeholder="Authorization token"
              invalid={error.authToken}
              enterKeyHint="go"
              value={authToken}
              handler={e => setAuthToken(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter')
                  handleSubmit(
                    username,
                    email,
                    password,
                    authToken,
                    setError,
                    navigate
                  );
              }}
              leftIcon={
                <MdVpnKey className="h-5 w-5 fill-white" />
              }
            />
          </div>
        </div>
      </div>
      <div className="w-72 space-y-8 md:w-80">
        <Button
          text="Sign up"
          onClick={() =>
            handleSubmit(
              username,
              email,
              password,
              authToken,
              setError,
              navigate
            )
          }
        />
        <p className="w-full select-none text-center font-heading text-sm font-light">
          Already have an account?{' '}
          <span
            className="cursor-pointer font-normal text-[#5168F4]"
            onClick={() => navigate('/')}>
            Sign in
          </span>
        </p>
      </div>
    </div>
  );
};

export default Register;
