import { useState, createContext, useEffect } from 'react';

export const StepperContext = createContext();

export const StepperProvider = ({ children, length }) => {
  const [stepIndex, setStepIndex] = useState(1);
  const [prevStepIndex, setPrevStepIndex] = useState(0);

  useEffect(() => {
    setPrevStepIndex(oldState => {
      // initial state
      if (oldState === 0 && stepIndex === 2) return 1;
      // Guard 0 state
      if (oldState === 0) return oldState;

      if (stepIndex === 1 && oldState === length) return 0;
      //

      if (stepIndex > oldState && stepIndex !== oldState)
        return oldState + 1;

      if (stepIndex < oldState && stepIndex !== oldState)
        return oldState - 1;

      if (stepIndex === oldState && stepIndex !== 1)
        return oldState + 1;
      if (stepIndex === oldState && stepIndex === 1)
        return oldState - 1;

      return oldState;
    });
  }, [stepIndex, length]);

  return (
    <StepperContext.Provider
      value={{
        stepState: [stepIndex, setStepIndex],
        prevStep: prevStepIndex,
      }}>
      {children}
    </StepperContext.Provider>
  );
};
