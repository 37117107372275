import { toast } from 'react-toastify';
import FinishedCard from './components/FinishedCard';
import UnfinishedCard from './components/UnfinishedCard';
import { searchTermMatches } from '../../helper/utils';

export const getFinishedTests = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_DOMAIN}/api/test/finished-tests`,
    {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
    }
  );

  if (response.status === 200) {
    const json = await response.json();

    return json;
  }
};

export const getUnfinishedTests = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_DOMAIN}/api/test/unfinished-tests`,
    {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
    }
  );

  if (response.status === 200) {
    const json = await response.json();

    return json;
  }
};

const isDateBeforeToday = date => {
  return new Date(date) < new Date();
};

export const getTestsByDate = ({ tests }, isFinished) => {
  if (tests.length === 0) return [];
  let dates = [];
  let testList = {};

  tests.forEach((test, key) => {
    let label = new Date().toJSON().slice(0, 10);

    if (dates.includes(test.date)) label = null;
    else {
      if (!testList?.[test.date]) {
        if (!isDateBeforeToday(test.date))
          testList = { [test.date]: [], ...testList };
        else testList = { ...testList, [test.date]: [] };
      }

      dates.push(test.date);

      const date = new Date(test.date);
      const formattedDate = date.toLocaleString('en-UK', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });

      if (label === test.date)
        label = `Today, ${formattedDate}`;
      else label = formattedDate;
    }

    if (!isFinished)
      testList[test.date].push(
        <UnfinishedCard
          key={key}
          name={test.name}
          author={test.author}
          progress={test.progress}
          time={test.startTime}
          date={label}
        />
      );
    else
      testList[test.date].push(
        <FinishedCard
          key={key}
          name={test.name}
          author={test.author}
          startTime={test.startTime}
          endTime={test.endTime}
          pdfDownloaded={test.pdfDownloaded}
          date={label}
        />
      );
  });

  return Object.values(testList);
};

export const deleteCard = async name => {
  const response = await fetch(
    `${process.env.REACT_APP_DOMAIN}/api/test/delete`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      mode: 'cors',

      body: JSON.stringify({
        name: name,
      }),
    }
  );

  if (response.status === 200) {
    const json = await response.json();

    return json;
  }
};

export const handleSubmit = async (
  { testName, testDate, testGaskets },
  setOpen,
  setState,
  setStepIndex
) => {
  let error = false;

  if (testName.length < 3) {
    setState(oldState => ({
      ...oldState,
      testNameError: true,
    }));

    error = true;
    setStepIndex(1);
    toast.error(
      'The test name must be at least 3 characters long!'
    );
  }

  if (testGaskets <= 0) {
    setState(oldState => ({
      ...oldState,
      testGasketsError: true,
    }));

    error = true;
    setStepIndex(3);
    toast.error(
      'The number of gaskets cannot be smaller or equal to 0!'
    );
  }

  if (!error) {
    setState({
      testName: '',
      testDate: new Date().toJSON().split('T')[0],
      testGaskets: '',
      testNameError: false,
      testDateError: false,
      testGasketError: false,
    });

    const response = await fetch(
      `${process.env.REACT_APP_DOMAIN}/api/test/create`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        mode: 'cors',

        body: JSON.stringify({
          name: testName,
          author: sessionStorage.getItem('user'),
          date: testDate,
          gaskets: testGaskets,
        }),
      }
    );

    const json = await response.json();

    if (response.status === 200) {
      toast.success(json.message);
      setOpen(false);
      window.dispatchEvent(new Event('update-tests'));
    } else toast.error(json?.error);
  }
};

export const filterTests = (tests, searchTerm) => {
  if (tests.length === 0) return [];
  else {
    const arr = tests.filter(e => {
      if (
        searchTermMatches(e[0].props.author, searchTerm) ||
        searchTermMatches(e[0].props.name, searchTerm)
      )
        return e;
      return null;
    });

    return arr;
  }
};
