import { toast } from 'react-toastify';

const validate = (username, password, setError) => {
  let error = false;

  if (password === '' || password.length < 6) {
    setError(error => ({ ...error, password: true }));

    error =
      'The password specified must be at least 6 characters long!';
  } else setError(error => ({ ...error, password: null }));

  if (username === '' || username.length < 3) {
    setError(error => ({ ...error, username: true }));

    error =
      'The username specified must be at least 3 characters long!';
  } else setError(error => ({ ...error, username: null }));

  if (error) toast.error(error);
  else return 1;
};

const login = async (username, password) => {
  const response = await fetch(
    `${process.env.REACT_APP_DOMAIN}/api/user/login`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      mode: 'cors',

      body: JSON.stringify({
        name: username,
        password: password,
      }),
    }
  );

  if (response.status !== 200) {
    const json = await response.json();

    return json.error;
  }
};

export const handleSubmit = async (
  username,
  password,
  setError,
  navigate,
  onLogin
) => {
  if (validate(username, password, setError)) {
    const error = await login(username, password);

    if (!error) {
      onLogin(username);
      navigate('/dashboard');
    } else {
      setError({ username: true, password: true });

      toast.error(error);
    }
  }
};
