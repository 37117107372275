import { useContext } from 'react';
import { TabsContext } from '../../context/TabsContext';

const Tab = ({ id, label, icon, children }) => {
  const [tabIndex, setTabIndex] = useContext(TabsContext);

  return (
    <div className="flex flex-col w-full h-full">
      <div
        className={`space-x-2 pl-2 select-none flex items-center justify-center h-8 ${
          tabIndex === id
            ? 'fill-[#96A5FF] text-[#96A5FF]'
            : 'border-[#292B2F] fill-white/50 text-white/50 cursor-pointer hover:fill-white hover:text-white transition-colors'
        }`}
        onClick={() => setTabIndex(id)}>
        {icon}
        <p className="text-heading font-sm">{label}</p>
      </div>

      {tabIndex === id && (
        <div className="w-full absolute left-0 top-12 h-full">
          {children}
        </div>
      )}
    </div>
  );
};

export default Tab;
