export const searchTermMatches = (
  termToCheck,
  searchTerm
) => {
  if (
    termToCheck
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  )
    return true;
  else if (searchTerm === '') return true;
  else return false;
};

export const secondsToHms = s => ({
  hours: ((s - (s % 3600)) / 3600) % 60,
  minutes: ((s - (s % 60)) / 60) % 60,
  seconds: s % 60,
});

export const zeroPad = (num, places) =>
  String(num).padStart(places, '0');

export const timeToSeconds = timeString =>
  timeString
    .split(':')
    .reduce((acc, time) => 60 * acc + +time);
