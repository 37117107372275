const NumericInput = ({
  currRef,
  type = 'text',
  placeholder = 'Enter text here.',
  value,
  handler,
  leftIcon,
  rightSection,
  label,
  step,
  min = 0,
  invalid,
  autoFocus = false,
  enterKeyHint = 'next',
  onBlur,
  onKeyDown,
}) => {
  return (
    <div>
      {label && (
        <span className="mb-1.5 font-heading text-sm opacity-70">
          {label}
        </span>
      )}
      <div className="flex h-10 w-72 rounded-lg border-2 border-[#292B2F] bg-[#181920] md:w-80">
        {leftIcon && (
          <div className="mx-3 grid h-full w-10 place-items-center opacity-70">
            {leftIcon}
          </div>
        )}
        <input
          className={`h-10 w-full rounded-none border-2 bg-transparent px-3 font-heading text-sm placeholder-white/50 outline-none focus:border-[#5168F4] focus:outline-none ${
            invalid ? 'border-red-500' : 'border-[#292B2F]'
          } -mt-0.5 transition-colors`}
          ref={currRef}
          type={type}
          step={step}
          placeholder={placeholder}
          value={value}
          min={min}
          onChange={handler}
          onBlur={onBlur}
          enterKeyHint={enterKeyHint}
          autoFocus={autoFocus}
          onFocus={e => e.target.select()}
          onKeyDown={onKeyDown}
        />
        {rightSection && (
          <p className="mx-3 grid h-full w-32 select-none place-items-center font-heading font-light opacity-70">
            {rightSection}
          </p>
        )}
      </div>
    </div>
  );
};

export default NumericInput;
