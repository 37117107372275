import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from '../../../components';
import {
  MdAccessTime,
  MdDeleteForever,
  MdPictureAsPdf,
} from 'react-icons/md';
import DeleteModal from './DeleteModal';

const FinishedCard = ({
  name,
  author,
  pdfDownloaded,
  startTime,
  endTime,
  date,
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="flex w-full items-center justify-between">
      <DeleteModal
        open={open}
        setOpen={setOpen}
        name={name}
        author={author}
        pdf={pdfDownloaded}
        finished={true}
      />
      <Card
        size="h-20 md:h-24 w-full"
        color="bg-[#51F47F]"
        title={date}
        onClick={() =>
          navigate(
            `./${name}/gasket-results?completed=true`
          )
        }
        leftSection={
          <div className="text-heading flex h-full select-none flex-col justify-between py-1 text-sm leading-snug">
            <p className="font-bold">{name}</p>
            <p className="opacity-70">By {author}</p>
            <div className="flex items-center space-x-2">
              <MdAccessTime className="h-3 w-3 fill-white opacity-50" />
              <p>
                {startTime?.slice(0, -3)} -{' '}
                {endTime?.slice(0, -3)}
              </p>
            </div>
          </div>
        }
        rightSection={
          <div className="flex h-full flex-col items-center justify-center">
            <div
              className={`${
                pdfDownloaded
                  ? 'bg-[#1C442D]'
                  : 'bg-[#3E1110]'
              } relative h-6 w-6 rounded-md p-1`}>
              <MdPictureAsPdf
                className={`h-full w-full ${
                  pdfDownloaded
                    ? 'fill-[#34965E]'
                    : 'fill-[#D85656]'
                }`}
              />
              {!pdfDownloaded && (
                <span className="absolute -top-0.5 -right-0.5 grid h-2 w-2 place-items-center rounded-full bg-red-400">
                  <span className="h-full w-full animate-ping rounded-full bg-red-500" />
                </span>
              )}
            </div>
          </div>
        }
        rightIcon={
          <MdDeleteForever
            className="mr-2 h-8 w-8 cursor-pointer rounded-full fill-red-500 p-1 transition-all hover:bg-white/10"
            onClick={() => setOpen(true)}
          />
        }
      />
    </div>
  );
};

export default FinishedCard;
