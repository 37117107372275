const getGasketTest = async (id, testName) => {
  const response = await fetch(
    `${
      process.env.REACT_APP_DOMAIN
    }/api/gaskets/get-gasket-test?${new URLSearchParams({
      id: id,
      testName: encodeURIComponent(testName),
    })}`,
    {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
    }
  );

  if (response.status === 200) {
    const json = await response.json();

    return json;
  } else return null;
};

export default getGasketTest;
