import { useContext, useState, useEffect } from 'react';
import {
  Drawer,
  Stepper,
  Button,
  Input,
} from '../../../components';
import { StepperContext } from '../../../context/StepperContext';
import {
  MdDriveFileRenameOutline,
  MdDateRange,
  MdDonutLarge,
} from 'react-icons/md';
import { handleSubmit } from '../testlist-logic';

const Step = ({
  title,
  state,
  name,
  setState,
  placeholder,
  setOpen,
}) => {
  const { stepState } = useContext(StepperContext);
  const [stepIndex, setStepIndex] = stepState;

  return (
    <div className="flex h-full flex-col items-center justify-between pb-32 pt-8 text-center">
      <div>
        <p className="mb-4 font-heading text-sm font-light opacity-70">
          {title}
        </p>
        <Input
          type={
            stepIndex === 3
              ? 'number'
              : stepIndex === 2
              ? 'date'
              : 'text'
          }
          value={state[name]}
          invalid={state[`${name}Error`]}
          placeholder={placeholder}
          enterKeyHint={stepIndex === 3 ? 'go' : 'next'}
          onKeyDown={e => {
            if (e.key === 'Enter' && state[name] !== '') {
              if (stepIndex !== 3)
                setStepIndex(stepIndex + 1);
              else if (stepIndex === 3) {
                e.target.blur();
                handleSubmit(
                  state,
                  setOpen,
                  setState,
                  setStepIndex
                );
              }
            }
          }}
          rightSection={
            stepIndex === 2 && (
              <div className="grid h-6 w-6 place-items-center rounded-full border border-transparent bg-[#3B3F48] p-1 shadow-sm transition-all active:-translate-y-0.5 active:border-blue-400">
                <MdDateRange className="h-full w-full fill-white active:fill-blue-400" />
              </div>
            )
          }
          handler={e =>
            setState(oldState => ({
              ...oldState,
              [name]: e.target.value,
            }))
          }
          autoFocus={
            stepIndex !== 2 && !state[`${name}Error`]
          }
        />
      </div>
      <div className="flex w-4/5 items-center justify-between space-x-4">
        <Button
          text={
            stepIndex !== 3 ? 'Continue' : 'Create Test'
          }
          disabled={state[name] === ''}
          onClick={() => {
            if (stepIndex < 3) setStepIndex(stepIndex + 1);
            else if (stepIndex === 3) {
              handleSubmit(
                state,
                setOpen,
                setState,
                setStepIndex
              );
            }
          }}
        />
        <div className="h-full w-1/2">
          <Button
            text="Back"
            variant="subtle"
            disabled={stepIndex === 1}
            onClick={() => {
              if (stepIndex >= 1)
                setStepIndex(stepIndex - 1);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const CreateTestDrawer = ({ open, setOpen }) => {
  const [state, setState] = useState({
    testName: '',
    testNameError: false,
    testDate: new Date().toJSON().split('T')[0],
    testGaskets: '',
    testGasketsError: false,
  });

  useEffect(() => {
    if (!open)
      setState({
        testName: '',
        testDate: new Date().toJSON().split('T')[0],
        testGaskets: '',
        testNameError: false,
        testGasketsError: false,
      });
  }, [open]);

  return (
    <Drawer
      title="Create Test"
      open={open}
      setOpen={() => setOpen(false)}
      rounded="rounded-t-lg"
      height="h-3/5">
      <div className="relative h-full w-full fill-white pt-2 text-white">
        <Stepper forceHorizontal>
          <Stepper.Step
            title="Step 1"
            label="Project"
            icon={
              <MdDriveFileRenameOutline className="h-full w-full" />
            }>
            <Step
              title="Insert a project number"
              state={state}
              name="testName"
              setState={setState}
              placeholder="Project number"
            />
          </Stepper.Step>
          <Stepper.Step
            title="Step 2"
            label="Date"
            icon={
              <MdDateRange className="h-full w-full" />
            }>
            <Step
              title="Insert a date"
              state={state}
              name="testDate"
              setState={setState}
            />
          </Stepper.Step>
          <Stepper.Step
            title="Step 3"
            label="Gaskets"
            icon={
              <MdDonutLarge className="h-full w-full" />
            }>
            <Step
              title="Insert amount of gaskets"
              state={state}
              name="testGaskets"
              setState={setState}
              placeholder="Number"
              setOpen={setOpen}
            />
          </Stepper.Step>
        </Stepper>
      </div>
    </Drawer>
  );
};

export default CreateTestDrawer;
