const isMatched = (search, term) => {
  if (term.toLowerCase().includes(search.toLowerCase()))
    return false;
  else if (search === '') return false;

  return true;
};

const Item = ({
  text,
  icon,
  selected,
  setSelected,
  isFocused,
  setOpen,
  editable,
}) => {
  if (isMatched(selected, text) && editable) return null;

  return (
    <div className="my-1 w-full px-1">
      <div
        className={`flex h-10 w-full cursor-pointer select-none items-center space-x-2 rounded-lg px-2 drop-shadow-md transition-colors hover:bg-[#252A34]/60 ${
          selected === text &&
          'bg-[#252A34]/30 text-white/80'
        } ${isFocused && 'bg-[#252A34]/50 text-white/80'}`}
        onClick={() => {
          setSelected(text);
          setOpen(false);
        }}>
        {icon}
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Item;
