const Result = ({
  leftIcon,
  text,
  rightSection = '-',
  error,
}) => {
  return (
    <div className="flex h-10 w-full items-center justify-between font-heading">
      {leftIcon && (
        <div className="mx-3 grid h-full w-10 place-items-center">
          {leftIcon}
        </div>
      )}
      <span
        className={`w-full text-center ${
          error && 'text-red-400'
        }`}>
        {text}
      </span>
      <span
        className={`mx-3 grid h-full w-32 select-none place-items-center font-heading font-light ${
          error && 'text-red-400'
        }`}>
        {rightSection}
      </span>
    </div>
  );
};

export default Result;
