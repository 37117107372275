import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MdAssignment,
  MdPlaylistAddCheck,
  MdSettings,
} from 'react-icons/md';

const NavbarSlider = ({ offset }) => {
  return (
    <div
      className="absolute -right-[1.65rem] top-0 grid h-1/3 place-items-center transition-transform duration-300 will-change-transform"
      style={{
        transform: `translateY(${offset}%)`,
      }}>
      <hr className="h-11 w-[3px] rounded-full border-none bg-white" />
    </div>
  );
};

const NavItem = ({
  name,
  onClick,
  icon,
  setOffset,
  selected,
  selectedNum = 1,
}) => {
  return (
    <div className="h-1/3 w-full">
      <li
        onClick={onClick}
        onMouseEnter={() =>
          setOffset((selectedNum - 1) * 100)
        }
        onMouseLeave={() => setOffset((selected - 1) * 100)}
        className={`flex h-16 items-center space-x-3 font-heading text-base font-light transition-colors ${
          selected === selectedNum
            ? 'fill-white'
            : 'cursor-pointer text-white/50 hover:fill-white hover:text-white'
        }`}>
        {icon}
        <span>{name}</span>
      </li>
    </div>
  );
};

const NavbarDesktop = ({ selected, setSelected }) => {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setOffset((selected - 1) * 100);
  }, [selected]);

  return (
    <div className="absolute top-24 select-none border-t border-[#2F3036] px-2 pt-8">
      <ul className="relative">
        <NavbarSlider offset={offset} />
        <NavItem
          name="Todo"
          onClick={() => {
            setSelected(1);
            navigate('./');
          }}
          icon={<MdAssignment className="h-6 w-6" />}
          setOffset={setOffset}
          selected={selected}
          selectedNum={1}
        />
        <NavItem
          name="Results"
          onClick={() => {
            setSelected(2);
            navigate('./gasket-results?completed=false');
          }}
          icon={<MdPlaylistAddCheck className="h-6 w-6" />}
          setOffset={setOffset}
          selected={selected}
          selectedNum={2}
        />
        <NavItem
          name="Settings"
          onClick={() => {
            setSelected(3);
            navigate('./test-settings');
          }}
          icon={<MdSettings className="h-6 w-6" />}
          setOffset={setOffset}
          selected={selected}
          selectedNum={3}
        />
      </ul>
    </div>
  );
};

export default NavbarDesktop;
