import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CircularProgressBar,
} from '../../../components';
import {
  MdAccessTime,
  MdDeleteForever,
} from 'react-icons/md';
import DeleteModal from './DeleteModal';

const UnfinishedCard = ({
  name,
  author,
  progress,
  time,
  date,
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="flex w-full items-center justify-between">
      <DeleteModal
        open={open}
        setOpen={setOpen}
        name={name}
        author={author}
        pdf={true}
        finished={false}
      />
      <Card
        size="h-20 md:h-24 w-full"
        color="bg-[#5168F4]"
        title={date}
        onClick={() =>
          navigate(`./${encodeURIComponent(name)}`)
        }
        leftSection={
          <div className="text-heading flex h-full select-none flex-col justify-between py-1 text-sm leading-snug">
            <p className="font-bold">{name}</p>
            <p className="opacity-70">By {author}</p>
            <div className="flex items-center space-x-2">
              <MdAccessTime className="h-3 w-3 fill-white opacity-50" />
              <p>{time.slice(0, -3)}</p>
            </div>
          </div>
        }
        rightSection={
          <CircularProgressBar
            size={53}
            strokeWidth={5}
            percentage={progress}
            color="#7286FF"
          />
        }
        rightIcon={
          <MdDeleteForever
            className="mr-2 h-8 w-8 cursor-pointer rounded-full fill-red-500 p-1 transition-all hover:bg-white/10"
            onClick={() => setOpen(true)}
          />
        }
      />
    </div>
  );
};

export default UnfinishedCard;
