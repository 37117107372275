import { SearchBar, Tabs } from '../../../components';
import {
  MdPendingActions,
  MdAssignmentTurnedIn,
  MdOutlineAdd,
} from 'react-icons/md';
import CreateTestDrawer from './CreateTestDrawer';

const TestListMobile = ({
  tests,
  search,
  setSearch,
  open,
  setOpen,
}) => {
  return (
    <div className="mt-6 h-2/3 w-5/6 space-y-4 pb-24 md:hidden">
      {/* Header */}
      <div className="relative flex w-full">
        <p className="select-none font-heading text-xl">
          Tests
        </p>
      </div>
      {/* Tabs */}
      <Tabs>
        <Tabs.Tab
          id={1}
          label="Uncompleted"
          icon={<MdPendingActions className="h-5 w-5" />}>
          <div className="absolute right-0 -top-24 w-full">
            <SearchBar
              value={search.unfinished}
              setValue={value => {
                setSearch(oldState => ({
                  ...oldState,
                  unfinished: value,
                }));
              }}
            />
          </div>
          {!tests.loading &&
            (tests.unfinished.length === 0 ? (
              <div className="mt-2 h-full w-full font-heading text-sm font-extralight opacity-70">
                {search.unfinished === '' &&
                  'No tests started currently. Please create a new one by pressing the button below.'}
              </div>
            ) : (
              <div className="h-full w-full">
                <div className="mt-2 h-full w-full space-y-4 overflow-y-auto">
                  {tests.unfinished}
                </div>
              </div>
            ))}
          {!open && !tests.loading && (
            <div className="mt-4 flex w-full justify-center">
              <div
                className={`grid h-12 w-12 cursor-pointer place-items-center rounded-full bg-[#252A34] shadow-md transition-all active:translate-y-0.5 ${
                  tests.unfinished.length === 0 &&
                  search.unfinished === '' &&
                  'animate-bounce'
                }`}
                onClick={() => setOpen(true)}>
                <MdOutlineAdd className="h-10 w-10 fill-white" />
              </div>
            </div>
          )}
        </Tabs.Tab>
        <Tabs.Tab
          id={2}
          label="Completed"
          icon={
            <MdAssignmentTurnedIn className="h-5 w-5" />
          }>
          <div className="absolute right-0 -top-24 w-full">
            <SearchBar
              value={search.finished}
              setValue={value => {
                setSearch(oldState => ({
                  ...oldState,
                  finished: value,
                }));
              }}
            />
          </div>
          {!tests.loading &&
            (tests.finished.length === 0 ? (
              <div className="mt-2 h-full w-full font-heading text-sm font-extralight opacity-70">
                {search.finished === '' &&
                  'No tests finished yet!'}
              </div>
            ) : (
              <div className="mt-2 h-full w-full space-y-4 overflow-y-auto">
                {tests.finished}
              </div>
            ))}
        </Tabs.Tab>
      </Tabs>

      <CreateTestDrawer open={open} setOpen={setOpen} />
    </div>
  );
};

export default TestListMobile;
