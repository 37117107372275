import { toast } from 'react-toastify';
import { addSensitivity } from '../../../../api/sensitivity';
import { zeroPad } from '../../../../helper/utils';

const calculateSensitivity = (
  readingBg,
  readingRefLeak,
  refValue,
  inspectionType
) => {
  let refValueFactor;

  if (
    inspectionType === 'Accumulation' ||
    inspectionType === 'Concentration Sniffing'
  )
    refValueFactor = 'e-6';

  const sensitivity =
    parseFloat(`${refValue}${refValueFactor}`) /
    (parseFloat(readingRefLeak) - parseFloat(readingBg));

  return sensitivity.toFixed(2);
};

export const validateParams = ({
  refValue,
  readingBg,
  readingRefLeak,
}) => {
  if (refValue === '' || parseFloat(refValue) <= 0)
    return true;

  if (readingBg === '' || parseFloat(readingBg) <= 0)
    return true;

  if (
    readingRefLeak === '' ||
    parseFloat(readingRefLeak) <= 0
  )
    return true;

  return false;
};

export const handleSubmit = async (
  { readingBg, readingRefLeak, refValue },
  { inspectionType },
  user,
  testName,
  setState,
  id
) => {
  const dateObj = new Date();
  const date = dateObj.toLocaleDateString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  const time = dateObj.toLocaleTimeString();

  const sensitivity = calculateSensitivity(
    readingBg,
    readingRefLeak,
    refValue,
    inspectionType
  );

  if (
    parseFloat(sensitivity) <= 0 ||
    parseFloat(sensitivity) === Infinity
  )
    return toast.error(
      'Sensitivity cannot be zero, negative or infinite!'
    );

  const body = {
    sensitivity: parseFloat(sensitivity),
    endDate: date,
    endTime: time,
    author: user,
    inspType: inspectionType,
    refValue: parseFloat(refValue),
    testName: testName,
    readingBg: parseFloat(readingBg),
    readingRefLeak: parseFloat(readingRefLeak),
    id: parseFloat(id),
  };

  const json = await addSensitivity(body);

  if (json?.error) toast.error(json.error);
  else {
    toast.success(json?.message);

    setState(prev => ({
      ...prev,
      sensitivity: sensitivity,
      endDate: date,
      endTime: zeroPad(time),
      author: user,
    }));
  }
};

export const resetState = setState => {
  setState(prev => ({
    ...prev,
    sensitivity: '',
    endTime: '',
    endDate: '',
    author: '',
  }));
};

export const isInvalidSensitivity = (endDate, endTime) => {
  const dateParts = endDate.split('-');
  const parsedDate = new Date(
    `${
      dateParts[1]
    }/${+dateParts[0]}/${+dateParts[2]} ${endTime}`
  );

  const timeDiff =
    Date.parse(new Date()) - Date.parse(parsedDate);
  const timeDiffHour = timeDiff / 1000 / 3600;
  const invalidSensitivity = Math.round(
    timeDiffHour >=
      Math.floor(
        process.env.REACT_APP_CALIBRATION_TIMEOUT
      ) /
        3600
  );

  return invalidSensitivity;
};
