import { cloneElement } from 'react';
import { StepperProvider } from '../../context/StepperContext';

const Stepper = ({
  forceHorizontal,
  length = 3,
  children,
}) => {
  return (
    <StepperProvider length={length}>
      <div
        className={`flex ${
          !forceHorizontal ? 'flex-col md:h-full' : 'h-full'
        }
        relative w-full justify-between md:flex-row`}>
        {children.length
          ? children.map((child, key) =>
              cloneElement(child, {
                key: key,
                id: key + 1,
                last: length === key + 1,
                forceHorizontal: forceHorizontal,
              })
            )
          : cloneElement(children, {
              id: 1,
              last: true,
              forceHorizontal: forceHorizontal,
            })}
      </div>
    </StepperProvider>
  );
};

export default Stepper;
