import {
  SearchBar,
  SideBarDesktop,
} from '../../../components';
import {
  MdPendingActions,
  MdAssignmentTurnedIn,
} from 'react-icons/md';
import CreateTestModal from './CreateTestModal';

const List = ({
  title,
  icon,
  name,
  tests,
  search,
  setSearch,
}) => {
  return (
    <div className="flex h-full w-full flex-col space-y-6">
      <div className="flex items-center space-x-2">
        {icon}
        <p className="select-none font-heading text-xl">
          {title}
        </p>
      </div>
      <SearchBar
        value={search[name]}
        setValue={value => {
          setSearch(oldState => ({
            ...oldState,
            [name]: value,
          }));
        }}
      />

      {!tests?.loading && tests?.[name].length === 0 ? (
        <div className="w-full select-none font-heading text-sm font-extralight opacity-70">
          {search[name] === ''
            ? name === 'unfinished'
              ? 'No tests started currently. Please create a new one by pressing the button below.'
              : 'No tests finished yet!'
            : null}
        </div>
      ) : (
        <div className="h-96 w-full space-y-2 overflow-auto">
          {tests?.[name]}
        </div>
      )}
    </div>
  );
};

const TestListDesktop = ({
  tests,
  search,
  setSearch,
  open,
  setOpen,
}) => {
  return (
    <div className="hidden h-full w-full justify-center pt-10 md:flex">
      <SideBarDesktop
        showFooter
        footerBounce={
          tests.unfinished.length === 0 &&
          search.unfinished === ''
        }
        footerAction={() => setOpen(true)}
      />
      <CreateTestModal open={open} setOpen={setOpen} />
      <div className="h-full w-3/5">
        <p className="w-full select-none border-b border-white/10 pb-3 font-heading text-2xl">
          Tests
        </p>
        <div className="mt-6 flex w-full space-x-20">
          <List
            title="Uncompleted"
            icon={
              <MdPendingActions className="h-5 w-5 fill-white/70" />
            }
            name="unfinished"
            tests={tests}
            search={search}
            setSearch={setSearch}
          />
          <List
            title="Completed"
            icon={
              <MdAssignmentTurnedIn className="h-5 w-5 fill-white/70" />
            }
            name="finished"
            tests={tests}
            search={search}
            setSearch={setSearch}
          />
        </div>
      </div>
    </div>
  );
};

export default TestListDesktop;
