import { useState, createContext } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(
    sessionStorage.getItem('user') || null
  );

  const handleRefresh = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_DOMAIN}/api/user/authorize`,
      {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
      }
    );

    const json = await response.json();

    return json;
  };

  const handleLogin = user => {
    sessionStorage.setItem('user', user);

    setUser(user);
  };

  const handleLogout = async () => {
    await fetch(
      `${process.env.REACT_APP_DOMAIN}/api/user/logout`,
      {
        method: 'DELETE',
        credentials: 'include',
        mode: 'cors',
      }
    );

    setUser(null);
    sessionStorage.removeItem('user');
    navigate('/');
  };

  const value = {
    user,
    setUser: setUser,
    onLogin: handleLogin,
    onLogout: handleLogout,
    onRefresh: handleRefresh,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
