const getSensitivity = async (
  testName,
  inspType,
  refValue
) => {
  const response = await fetch(
    `${
      process.env.REACT_APP_DOMAIN
    }/api/sensitivity/get-sensitivity?${new URLSearchParams(
      {
        testName: encodeURIComponent(testName),
        inspType: encodeURIComponent(inspType),
        refValue: encodeURIComponent(refValue),
      }
    )}`,
    {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
    }
  );

  if (response.status === 200) {
    const json = await response.json();

    return json;
  }
};

export default getSensitivity;
