import {
  useState,
  useRef,
  useEffect,
  cloneElement,
} from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import useOnClickOutside from '../../hooks/useOnClickOutside';

const Dropdown = ({
  placeholder = 'Pick one',
  editable,
  children,
  initial,
  autoFocus,
  onChange,
}) => {
  const [open, setOpen] = useState(!!autoFocus && !initial);
  const [selected, setSelected] = useState(initial || '');
  const [dropdownIndex, setDropdownIndex] = useState(0);
  const dropDownRef = useRef();

  useEffect(() => setSelected(initial), [initial]);

  useEffect(() => {
    if (onChange) onChange(selected);

    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    children.forEach((child, index) => {
      if (child.props.text === initial)
        setDropdownIndex(index);
    });
  }, [children, initial]);

  useEffect(() => {
    let inputRef = null;
    let timeout;

    const handleKeyPress = e => {
      const { code, target } = e;

      switch (code) {
        case 'ArrowUp':
          if (!open) return;

          setDropdownIndex(index =>
            index > 0 ? index - 1 : 0
          );

          timeout = setTimeout(() => {
            return;
          }, 50);
          break;
        case 'ArrowDown':
          if (!open) return;

          setDropdownIndex(index =>
            index < children?.length - 1 ? index + 1 : index
          );

          timeout = setTimeout(() => {
            return;
          }, 50);
          break;
        case 'Enter':
          setOpen(open => !open);
          clearTimeout(timeout);

          if (target.type === 'text') target.select();

          if (!open) return target.focus();

          setSelected(children[dropdownIndex]?.props.text);
          return;
        case 'Escape':
          return setOpen(false);
        default:
          return;
      }
    };

    if (dropDownRef.current) {
      inputRef = dropDownRef.current;

      inputRef.addEventListener('keydown', handleKeyPress);

      dropDownRef.current.focus();

      return () => {
        inputRef.removeEventListener(
          'keydown',
          handleKeyPress
        );

        clearTimeout(timeout);
      };
    }
  }, [children, dropdownIndex, autoFocus, open, initial]);

  useOnClickOutside(dropDownRef, () => setOpen(false));

  return (
    <div
      className="group relative focus:outline-none"
      ref={dropDownRef}
      tabIndex={editable ? null : '0'}>
      <div
        className={`${
          open ? 'border-[#5168F4]' : 'border-[#292B2F]'
        } mb-2 flex h-10 w-72 select-none items-center justify-between rounded-lg border-2 bg-[#181920] pr-1 pl-2 shadow-md transition-all focus-within:border-[#5168F4] group-focus:border-[#5168F4] md:w-80 ${
          !editable && 'cursor-pointer'
        }`}
        onClick={() => setOpen(!open)}>
        {editable ? (
          <input
            autoFocus={autoFocus}
            className="w-full bg-transparent font-heading text-base placeholder-white/50 outline-none"
            placeholder={placeholder}
            value={selected}
            onChange={e => setSelected(e.target.value)}
            onFocus={e => {
              if (selected !== '') e.target.select();
            }}
          />
        ) : (
          <p
            className={`${
              !selected && 'opacity-50'
            } font-heading text-base`}>
            {selected ? selected : placeholder}
          </p>
        )}

        <RiArrowDropDownLine
          className="h-6 w-6 fill-white opacity-50"
          onClick={() => setOpen(!open)}
        />
      </div>
      {open && (
        <div className="absolute z-10 w-full rounded-lg bg-[#181920] font-text text-white/70 shadow-md shadow-zinc-900">
          {children?.length
            ? children.map((child, key) =>
                cloneElement(child, {
                  key: key,
                  selected: selected,
                  setSelected: setSelected,
                  isFocused: key === dropdownIndex,
                  setOpen: setOpen,
                  editable: editable,
                })
              )
            : cloneElement(children, {
                selected: selected,
                setSelected: setSelected,
                setOpen: setOpen,
                isFocused: true,
                editable: editable,
              })}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
