import { ReactComponent as NotFound } from '../../assets/svg/notFound.svg';

const Null = () => {
  return (
    <div className="relative grid h-full w-full select-none flex-col place-items-center bg-white text-center font-heading text-lg font-semibold text-black md:text-2xl">
      <NotFound className="max-w-xs pb-40 md:max-w-lg" />
      <span className="absolute top-3/4 left-1/2 w-full -translate-y-3/4 -translate-x-1/2">
        The page you were trying to load was not found
      </span>
    </div>
  );
};

export default Null;
