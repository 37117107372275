import { useContext } from 'react';
import { TabsContext } from '../../context/TabsContext';

export const TabSlider = ({ tabsLength }) => {
  const [tabIndex] = useContext(TabsContext);

  return (
    <div className="absolute top-8 flex h-4 w-full items-center">
      <hr
        className="absolute h-1 border-none bg-[#96A5FF] transition-all duration-300 will-change-transform"
        style={{
          width: tabsLength
            ? `${100 / tabsLength}%`
            : '100%',
          transform:
            tabsLength &&
            `translateX(${(tabIndex - 1) * 100}%)`,
        }}
      />
      <hr className="h-[1px] w-full border-none bg-[#292B2F]" />
    </div>
  );
};
