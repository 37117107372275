import { toast } from 'react-toastify';

const validate = (
  username,
  email,
  password,
  authToken,
  setError
) => {
  let error = false;

  // Auth token checks
  if (authToken === '' || authToken.length !== 20) {
    setError(error => ({ ...error, authToken: true }));

    error =
      'The authorization token specified must be 20 characters long!';
  } else setError(error => ({ ...error, authToken: null }));

  // Password checks
  if (password === '' || password.length < 6) {
    setError(error => ({ ...error, password: true }));

    error =
      'The password specified must be at least 6 characters long!';
  } else setError(error => ({ ...error, password: null }));

  // Email checks
  if (
    !email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    setError(error => ({ ...error, email: true }));

    error = 'The email specified is invalid!';
  } else setError(error => ({ ...error, email: null }));

  // Username checks
  if (username === '' || username.length < 3) {
    setError(error => ({ ...error, username: true }));

    error =
      'The username specified must be at least 3 characters long!';
  } else setError(error => ({ ...error, username: null }));

  if (error) toast.error(error);
  else return 1;
};

const register = async (
  username,
  email,
  password,
  authToken
) => {
  const response = await fetch(
    `${process.env.REACT_APP_DOMAIN}/api/user/register`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      mode: 'cors',

      body: JSON.stringify({
        name: username,
        email: email,
        password: password,
        authToken: authToken,
      }),
    }
  );

  if (response.status !== 200) {
    const json = await response.json();

    return json.error;
  }
};

const parseError = (error, setError) => {
  if (error.includes('name')) {
    setError(error => ({ ...error, username: true }));

    toast.error('Username already exists!');
  } else if (error.includes('email')) {
    setError(error => ({ ...error, email: true }));

    toast.error('Email already exists!');
  } else {
    setError(error => ({ ...error, authToken: true }));

    toast.error('Authorization token does not match!');
  }
};

export const handleSubmit = async (
  username,
  email,
  password,
  authToken,
  setError,
  navigate
) => {
  if (
    validate(username, email, password, authToken, setError)
  ) {
    const error = await register(
      username,
      email,
      password,
      authToken,
      setError
    );

    if (!error) {
      navigate('/');
      toast.success(
        `Account ${email} succesfully created!`
      );
    } else {
      parseError(error, setError);
    }
  }
};
