import { useState } from 'react';

const Input = ({
  currentRef,
  type = 'text',
  placeholder = 'Enter text here.',
  value,
  handler,
  leftIcon,
  rightSection,
  label,
  invalid,
  autoFocus = false,
  enterKeyHint = 'next',
  onKeyDown,
}) => {
  const [showPass, setShowPass] = useState(false);

  return (
    <div>
      {label && (
        <p className="mb-1.5 font-heading text-sm opacity-70">
          {label}
        </p>
      )}
      <div
        className={`w-72 md:w-80 bg-[#181920] ${
          invalid ? 'border-red-500' : 'border-[#292B2F]'
        } h-10 border-2  rounded-lg transition-all focus-within:border-[#5168F4] flex`}>
        {leftIcon && (
          <div className="h-full grid place-items-center ml-3">
            {leftIcon}
          </div>
        )}
        <input
          className="bg-transparent font-heading text-sm placeholder-white/50 w-full h-full px-3 outline-none focus:outline-none"
          ref={currentRef}
          type={showPass ? 'text' : type}
          placeholder={placeholder}
          value={value}
          onChange={handler}
          enterKeyHint={enterKeyHint}
          autoFocus={autoFocus}
          onFocus={e => e.target.select()}
          onKeyDown={onKeyDown}
        />
        {(type === 'password' || !!rightSection) && (
          <div className="h-full mr-3 grid place-items-center">
            {type === 'password' ? (
              <p
                className="font-heading text-sm font-thin hover:text-blue-400 cursor-pointer select-none transition-all"
                onClick={() => setShowPass(!showPass)}>
                {showPass ? 'Hide' : 'Show'}
              </p>
            ) : (
              rightSection
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
