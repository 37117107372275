import { toast } from 'react-toastify';

export const handleSubmit = async (
  state,
  setState,
  testName,
  gaskets,
  navigate
) => {
  const response = await fetch(
    `${process.env.REACT_APP_DOMAIN}/api/test/update`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      mode: 'cors',

      body: JSON.stringify({
        initialName: testName,
        newName:
          state.testName !== '' ? state.testName : testName,
        gaskets:
          state.amount !== '' ? state.amount : gaskets,
      }),
    }
  );

  const json = await response.json();

  if (json?.error) toast.error(json.error);
  else {
    toast.success(json.message);

    navigate(
      `/dashboard/${
        state.testName !== '' ? state.testName : testName
      }/test-settings`
    );

    setState({
      testName: '',
      amount: '',
    });

    window.dispatchEvent(new Event('amount-change'));
  }
};
