import { useState, useEffect } from 'react';
import SideBarDesktop from '../SideBarDesktop';
import {
  Outlet,
  useParams,
  useLocation,
  useSearchParams,
  useNavigate,
} from 'react-router-dom';
import NavbarDesktop from './components/NavbarDesktop';
import NavBarMobile from './components/NavbarMobile';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const getInitialOffset = name => {
  switch (name) {
    case 'gasket-results':
      return 2;
    case 'test-settings':
      return 3;
    default:
      return 1;
  }
};

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(1);
  const { width } = useWindowDimensions();

  const { gasket } = useParams();
  const [searchParams] = useSearchParams();
  const completed = searchParams.get('completed');

  useEffect(() => {
    const initialOffset = getInitialOffset(
      location.pathname.split('/')[3]
    );

    setSelected(initialOffset);
  }, [location, navigate]);

  return (
    <>
      {!gasket &&
      (completed === null || completed === 'false') ? (
        width >= 768 ? (
          <SideBarDesktop
            navbar={
              <NavbarDesktop
                selected={selected}
                setSelected={setSelected}
              />
            }
          />
        ) : (
          <NavBarMobile
            selected={selected}
            setSelected={setSelected}
          />
        )
      ) : null}
      <Outlet />
    </>
  );
};

export default Navbar;
