import {
  useContext,
  useRef,
  useEffect,
  useState,
} from 'react';
import {
  NumericInput,
  Button,
  ResultViewContainer,
} from '../../../../components';
import { StepperContext } from '../../../../context/StepperContext';
import { ReactComponent as LdIcon } from '../../../../assets/svg/leakdetector.svg';
import { ReactComponent as RefGas } from '../../../../assets/svg/refgas.svg';
import { ReactComponent as CalibrationIcon } from '../../../../assets/svg/calibration.svg';
import {
  handleSubmit,
  validateParams,
  resetState,
  isInvalidSensitivity,
} from './calibration-step-logic';
import {
  MdCalculate,
  MdAccessTime,
  MdBubbleChart,
  MdMultilineChart,
} from 'react-icons/md';
import { FaRedoAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';

const { Result, ResultViewbox } = ResultViewContainer;

const CalibrationStep = ({
  params,
  user,
  testName,
  state,
  setState,
  id,
}) => {
  const { stepState } = useContext(StepperContext);
  const [stepIndex, setStepIndex] = stepState;
  const [invalidSensitivity, setInvalidSensitivity] =
    useState(false);

  const disabled = validateParams(state);
  const refs = {
    readingBg: useRef(),
    readingLeak: useRef(),
  };

  useEffect(() => {
    const invalid = isInvalidSensitivity(
      state.endDate,
      state.endTime
    );

    if (invalid)
      toast.error('Please recalculate the sensitivity!');

    setInvalidSensitivity(invalid);
  }, [state.endDate, state.endTime]);

  return (
    <div className="relative flex h-full">
      <div className="flex h-full w-full flex-col items-center space-y-10 font-text md:pt-10">
        <h1 className="select-none text-center md:text-2xl">
          {state.sensitivity === ''
            ? 'Calculate the sensitivity'
            : 'Sensitivity results'}
          <br />
          <span className="hidden font-heading opacity-70 md:inline-block md:text-sm">
            {state.sensitivity === ''
              ? 'Insert the necessary parameters to calculate the sensitivity here.'
              : 'Verify the sensitivity results and recalculate if necessary.'}
          </span>
        </h1>
        <div className="flex w-full items-center justify-center space-x-10">
          {state.sensitivity === '' ? (
            <div className="flex flex-col space-y-4">
              <NumericInput
                autoFocus
                type={
                  params.inspectionType ===
                    'Direct Sniffing' ||
                  params.inspectionType ===
                    'Vacuum Sniffing'
                    ? 'text'
                    : 'number'
                }
                placeholder="Number"
                label={
                  params.inspectionType ===
                    'Direct Sniffing' ||
                  params.inspectionType ===
                    'Vacuum Sniffing'
                    ? 'Reference Leak'
                    : 'Referencegas'
                }
                leftIcon={<RefGas />}
                rightSection={
                  params.inspectionType ===
                    'Direct Sniffing' ||
                  params.inspectionType ===
                    'Vacuum Sniffing'
                    ? 'mbar.l/s'
                    : 'ppm.v'
                }
                value={
                  state.inspectionType === 'Accumulation' ||
                  state.inspectionType ===
                    'Concentration Sniffing'
                    ? parseFloat(state.refValue)
                    : state.refValue
                }
                handler={e =>
                  setState(prev => ({
                    ...prev,
                    refValue: e.target.value,
                  }))
                }
                onKeyDown={e => {
                  if (
                    e.key === 'Enter' &&
                    e.target.value !== ''
                  )
                    refs.readingBg.current.focus();
                }}
              />
              <NumericInput
                currRef={refs.readingBg}
                label="Detector reading background"
                leftIcon={<LdIcon />}
                value={state.readingBg}
                handler={e =>
                  setState(prev => ({
                    ...prev,
                    readingBg: e.target.value,
                  }))
                }
                rightSection="-"
                placeholder="Number"
                onKeyDown={e => {
                  if (
                    e.key === 'Enter' &&
                    e.target.value !== ''
                  )
                    refs.readingLeak.current.focus();
                }}
                enterKeyHint="continue"
              />
              <NumericInput
                currRef={refs.readingLeak}
                label={`Detector reading reference ${
                  params.inspectionType ===
                    'Accumulation' ||
                  params.inspectionType ===
                    'Concentration Sniffing'
                    ? 'gas'
                    : 'leak'
                }`}
                leftIcon={<MdBubbleChart />}
                value={state.readingRefLeak}
                handler={e =>
                  setState(prev => ({
                    ...prev,
                    readingRefLeak: e.target.value,
                  }))
                }
                rightSection="-"
                placeholder="Number"
                enterKeyHint="go"
                onKeyDown={e => {
                  if (e.key === 'Enter' && !disabled) {
                    handleSubmit(
                      state,
                      params,
                      user,
                      testName,
                      setState,
                      id
                    );
                    e.target.blur();
                  }
                }}
              />
            </div>
          ) : (
            <div className="space-y-4">
              <ResultViewbox label="Sensitivity">
                <Result
                  leftIcon={
                    <MdMultilineChart className="h-6 w-6 opacity-70" />
                  }
                  text={state.sensitivity}
                />
                <Result
                  leftIcon={
                    <MdAccessTime className="h-6 w-6 opacity-70" />
                  }
                  text={state.endDate}
                  rightSection={state.endTime}
                  error={invalidSensitivity}
                />
              </ResultViewbox>
              <ResultViewbox label="Sensitivity parameters">
                <Result
                  leftIcon={<RefGas />}
                  text={
                    params.inspectionType ===
                      'Direct Sniffing' ||
                    params.inspectionType ===
                      'Vacuum Sniffing'
                      ? parseFloat(
                          state.refValue
                        ).toExponential(1)
                      : parseFloat(state.refValue)
                  }
                  rightSection={
                    params.inspectionType ===
                      'Direct Sniffing' ||
                    params.inspectionType ===
                      'Vacuum Sniffing'
                      ? 'mbar.l/s'
                      : 'ppm.v'
                  }
                />
                <Result
                  leftIcon={<LdIcon />}
                  text={parseFloat(
                    state.readingBg
                  ).toExponential(1)}
                />
                <Result
                  leftIcon={<MdBubbleChart />}
                  text={state.readingRefLeak}
                />
              </ResultViewbox>
            </div>
          )}
          <CalibrationIcon className="hidden max-h-[250px] max-w-xs md:inline-flex" />
        </div>
      </div>
      <div className="absolute bottom-4 left-1/2 flex w-full max-w-lg -translate-x-1/2 items-center justify-between space-x-4 md:bottom-20">
        <Button
          text={
            state.sensitivity !== ''
              ? 'Next'
              : 'Calculate Sensitivity'
          }
          disabled={disabled}
          variant={state.sensitivity === '' && 'outline'}
          onClick={() => {
            if (state.sensitivity !== '')
              setStepIndex(stepIndex + 1);
            else if (!disabled)
              handleSubmit(
                state,
                params,
                user,
                testName,
                setState,
                id
              );
          }}
          icon={
            state.sensitivity === '' && (
              <MdCalculate className="h-6 w-6" />
            )
          }
        />
        {state.sensitivity !== '' && (
          <Button
            text="Recalculate"
            variant="outline"
            icon={<FaRedoAlt className="h-4 w-4" />}
            onClick={() => resetState(setState)}
          />
        )}
      </div>
    </div>
  );
};

export default CalibrationStep;
