import { useNavigate } from 'react-router-dom';
import { MdArrowRightAlt } from 'react-icons/md';

const ArrowBack = ({ label, path }) => {
  const navigate = useNavigate();
  return (
    <div
      className="flex items-center cursor-pointer space-x-2 group"
      onClick={() => navigate(path)}>
      <MdArrowRightAlt className="w-8 h-8 md:w-9 md:h-9 rotate-180 group-hover:bg-white/10 p-1 rounded-full transition-colors" />
      <span className="font-text text-sm font-light select-none opacity-70 group-hover:opacity-100 transition-opacity">
        {label}
      </span>
    </div>
  );
};

export default ArrowBack;
