import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { SpinnerCircular } from 'spinners-react';

const ProtectedRoute = ({ children }) => {
  const { user, setUser, onLogin, onRefresh } = useAuth();
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const refresh = async () => {
      const response = await onRefresh();

      if (response?.error) setUser(null);
    };

    const interval = setInterval(() => {
      refresh();
    }, 100000);

    return () => clearInterval(interval);
  }, [onRefresh, setUser]);

  useEffect(() => {
    if (!user) {
      const refresh = async () => {
        const response = await onRefresh();

        if (response?.error) setAuthenticated(false);
        else {
          setAuthenticated(true);
          onLogin(response.username);
        }

        setLoading(false);
      };

      refresh();
    } else {
      setAuthenticated(true);
      setLoading(false);
    }
  }, [user, onLogin, onRefresh]);

  return (
    <>
      {loading ? (
        <div className="grid h-full w-full place-items-center">
          <SpinnerCircular
            size={90}
            thickness={100}
            speed={96}
            color="rgba(255, 255, 255, 0.43)"
            secondaryColor="rgba(41, 43, 47, 1)"
          />
        </div>
      ) : authenticated ? (
        children
      ) : (
        <Navigate to="/" replace />
      )}
    </>
  );
};

export default ProtectedRoute;
