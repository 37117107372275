import { useState, useEffect } from 'react';
import {
  ArrowBack,
  ResultViewContainer,
  Button,
} from '../../components';
import {
  MdPictureAsPdf,
  MdDateRange,
  MdMultilineChart,
  MdAccountBox,
  MdOpacity,
  MdAccessTime,
  MdTimelapse,
  MdBubbleChart,
} from 'react-icons/md';
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { getGasketTest } from '../../api/gaskets';
import { FaRedoAlt } from 'react-icons/fa';
import {
  invalidateSensitivity,
  invalidateStemtime,
} from './gasket-result-logic';
import { ReactComponent as Banner } from '../../assets/svg/results.svg';
import { ReactComponent as LdIcon } from '../../assets/svg/leakdetector.svg';
import { ReactComponent as RefGas } from '../../assets/svg/refgas.svg';
import { ReactComponent as TestGas } from '../../assets/svg/testgas.svg';
import { ReactComponent as Volume } from '../../assets/svg/volume.svg';
import { setPdfDownloaded } from '../../api/gaskets';

const { Result, ResultViewbox } = ResultViewContainer;

const GasketResult = () => {
  const testName = decodeURIComponent(
    useLocation().pathname.split('/')[2]
  );
  const { gasket } = useParams();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [searchParams] = useSearchParams();
  const shouldContinue = searchParams.get('continue');
  const completed = searchParams.get('completed');
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState({
    readingLeak1: '',
    readingLeak2: '',
    inspectionType: '',
    name: '',
    author: '',
    startTime: '',
    startDate: '',
    endTime: '',
    endDate: '',
    vHood: '',
    concRaise: '',
    testGas: '',
    finalLeakValue: '',
    pdfDownloaded: '',
    refValue: '',
    sensitivity: '',
    sensTime: null,
    sensDate: '',
    sensAuthor: '',
    stemTime: '',
    readingBg: '',
    readingRefLeak: '',
    deltaTime: '',
  });

  useEffect(() => {
    getGasketTest(gasket, testName).then(gasket => {
      if (!gasket) return navigate('/dashboard');

      setState({
        ...gasket,
        refValue: gasket?.refValue?.toExponential(2),
        readingLeak1: gasket.readingLeak1.toExponential(2),
        readingLeak2: gasket.readingLeak2.toExponential(2),
        sensitivity: gasket.sensitivity,
        sensTime: gasket.sensTime,
        sensDate: gasket.sensDate,
        sensAuthor: gasket.sensAuthor,
        readingRefLeak: parseFloat(
          gasket.sensReadingRefLeak
        ).toExponential(2),
        readingBg: parseFloat(
          gasket.sensReadingBg
        ).toExponential(2),
        deltaTime: gasket?.deltaTime,
      });

      setIsLoading(false);
    });
  }, [gasket, testName, navigate]);

  return !isLoading ? (
    <div className="flex h-full w-full flex-col items-center overflow-y-hidden pt-4 pb-5">
      <div className="absolute top-2 left-4">
        <ArrowBack
          path={
            shouldContinue
              ? `/dashboard/${testName}`
              : `/dashboard/${testName}/gasket-results?completed=${completed}`
          }
          label={width >= 768 && 'Gasket results'}
        />
      </div>
      <section className="flex w-full items-center justify-center border-b border-[#292B2F] pb-5">
        <div className="flex w-11/12 max-w-6xl items-center justify-evenly 2xl:justify-between">
          <span className="select-none text-center font-heading text-2xl font-light leading-none md:text-left md:text-3xl">
            {state.name}
            <br />
            <span className="font-heading text-sm opacity-70">
              {state.inspectionType}
            </span>
          </span>

          <span
            className="absolute top-24 right-6 z-10 grid cursor-pointer place-items-center rounded-full p-1.5 transition-all hover:bg-white/10 active:translate-y-0.5 md:relative md:top-0 md:right-0 md:p-2"
            onClick={() => {
              setPdfDownloaded(
                {
                  id: gasket,
                  testName: testName,
                },
                state.name
              );
              setState(prev => ({
                ...prev,
                pdfDownloaded: true,
              }));
            }}>
            <MdPictureAsPdf
              className={`h-6 w-6 md:h-8 md:w-8 ${
                !state.pdfDownloaded
                  ? 'fill-red-400'
                  : 'fill-green-400'
              }`}
            />
            {!state.pdfDownloaded && (
              <span className="absolute top-1 right-0 grid h-2 w-2 place-items-center rounded-full bg-red-400">
                <span className="h-full w-full animate-ping rounded-full bg-red-500" />
              </span>
            )}
          </span>
        </div>
      </section>
      <div className="relative flex h-full w-11/12 flex-col items-center justify-between pt-5 font-text lg:max-w-6xl">
        {/* section */}
        <div className="flex h-3/4 w-full flex-col items-center md:flex-row md:justify-evenly lg:items-start lg:justify-between">
          <section className="flex h-full flex-col justify-evenly space-y-4 md:justify-start">
            <h1 className="hidden font-heading md:inline-flex md:text-lg lg:text-xl">
              Results
            </h1>
            <ResultViewbox label="Sensitivity">
              <Result
                leftIcon={
                  <MdMultilineChart className="h-6 w-6 opacity-70" />
                }
                text={state.sensitivity}
              />
              <Result
                leftIcon={
                  <MdDateRange className="h-6 w-6 opacity-70" />
                }
                text={state.sensDate}
                error={invalidateSensitivity(
                  state.sensTime,
                  state.sensDate,
                  state.endDate,
                  state.endTime
                )}
                rightSection={state.sensTime}
              />
              <Result
                leftIcon={
                  <MdAccountBox className="h-6 w-6 opacity-70" />
                }
                text={state.sensAuthor}
              />
            </ResultViewbox>
            <ResultViewbox label="Leakrate">
              <Result
                leftIcon={
                  <MdOpacity className="h-6 w-6 opacity-70" />
                }
                text={
                  state.finalLeakValue === 0
                    ? state.finalLeakValue
                    : state.inspectionType ===
                      'Concentration Sniffing'
                    ? state.finalLeakValue
                    : state.finalLeakValue.toExponential(2)
                }
                rightSection={
                  state.inspectionType ===
                  'Concentration Sniffing'
                    ? 'ppm.v'
                    : 'mbar.l/s'
                }
              />
              <Result
                leftIcon={
                  <MdDateRange className="h-6 w-6 opacity-70" />
                }
                text={state.sensDate}
                rightSection={state.endTime}
              />
              <Result
                leftIcon={
                  <MdAccountBox className="h-6 w-6 opacity-70" />
                }
                text={state.author}
              />
            </ResultViewbox>
            {state.inspectionType === 'Accumulation' && (
              <ResultViewbox label="Stemtime">
                <Result
                  leftIcon={
                    <MdAccessTime className="h-6 w-6 opacity-70" />
                  }
                  text={state.stemTime}
                />
                <Result
                  leftIcon={
                    <MdTimelapse className="h-6 w-6 opacity-70" />
                  }
                  text={state.deltaTime}
                  error={invalidateStemtime(
                    state.startTime,
                    state.startDate,
                    state.endTime,
                    state.endDate,
                    state.stemTime
                  )}
                />
              </ResultViewbox>
            )}
          </section>
          <span className="hidden max-w-xs items-center lg:flex">
            <Banner />
          </span>
          <section className="hidden h-full flex-col space-y-4 md:flex">
            <h1 className="hidden font-heading md:inline-flex md:text-lg lg:text-xl">
              Parameters
            </h1>
            <ResultViewbox label="Calibration Parameters">
              <Result
                leftIcon={<RefGas className="h-6 w-6" />}
                text={
                  state.inspectionType === 'Accumulation' ||
                  state.inspectionType ===
                    'Concentration Sniffing'
                    ? parseFloat(state.refValue)
                    : state.refValue
                }
                rightSection={
                  state.inspectionType === 'Accumulation' ||
                  state.inspectionType ===
                    'Concentration Sniffing'
                    ? 'ppm.v'
                    : 'mbar.l/s'
                }
              />
              <Result
                leftIcon={<LdIcon className="h-6 w-6" />}
                text={state.readingBg}
              />
              <Result
                leftIcon={
                  <MdBubbleChart className="h-6 w-6 opacity-70" />
                }
                text={state.readingRefLeak}
              />
            </ResultViewbox>
            <ResultViewbox
              label="Testing Parameters"
              oneResult={
                state.inspectionType !== 'Accumulation'
              }>
              <Result
                leftIcon={<TestGas className="h-6 w-6" />}
                text={state.testGas}
                rightSection="%"
              />
              {state.inspectionType === 'Accumulation' && (
                <Result
                  leftIcon={<Volume />}
                  text={state.vHood}
                  rightSection="liters"
                />
              )}
            </ResultViewbox>

            <ResultViewbox label="Test Readings">
              <Result
                leftIcon={
                  state.inspectionType ===
                  'Accumulation' ? (
                    <MdOpacity className="h-6 w-6 opacity-70" />
                  ) : (
                    <MdBubbleChart className="h-6 w-6 opacity-70" />
                  )
                }
                text={state.readingLeak1}
              />
              <Result
                leftIcon={
                  <MdOpacity className="h-6 w-6 opacity-70" />
                }
                text={state.readingLeak2}
              />
            </ResultViewbox>
          </section>
        </div>
        {/* Buttons */}
        <div className="absolute bottom-0 left-1/2 flex w-72 max-w-md -translate-x-1/2 space-x-4 md:w-full">
          {shouldContinue && (
            <Button
              text="Continue"
              onClick={() =>
                navigate(
                  `/dashboard/${testName}/${
                    parseInt(gasket) + 1
                  }`
                )
              }
            />
          )}
          {completed !== 'true' && (
            <Button
              text="Recalculate"
              variant="outline"
              icon={<FaRedoAlt className="h-4 w-4" />}
              onClick={() =>
                navigate(`/dashboard/${testName}/${gasket}`)
              }
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default GasketResult;
