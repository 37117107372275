import { TabsProvider } from '../../context/TabsContext';
import { TabSlider } from './TabSlider';

const Tabs = ({ children }) => {
  return (
    <TabsProvider>
      <div className="flex relative h-full">
        <TabSlider tabsLength={children.length} />
        {children}
      </div>
    </TabsProvider>
  );
};

export default Tabs;
