import { createPortal } from 'react-dom';
import { ToastContainer } from 'react-toastify';

const ToastPortal = () => {
  return createPortal(
    <ToastContainer limit={4} theme="colored" />,
    document.getElementById('toast-portal')
  );
};

export default ToastPortal;
