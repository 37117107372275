import { useNavigate } from 'react-router-dom';
import {
  MdAssignment,
  MdPlaylistAddCheck,
  MdSettings,
} from 'react-icons/md';

const NavSlider = ({ selected, refs }) => {
  return (
    <div
      className="absolute -bottom-2 grid w-1/3 place-items-center transition-transform duration-300 will-change-transform"
      style={{
        transform: `translateX(${(selected - 1) * 100}%)`,
      }}>
      <hr
        className={`z-20 h-0.5 w-5 rounded-full border-none bg-white `}
      />
    </div>
  );
};

const NavItem = ({
  icon,
  onClick,
  reference,
  selected,
  selectedNum,
}) => {
  return (
    <li
      ref={reference}
      onClick={onClick}
      className={`grid h-full w-full place-items-center transition-colors ${
        selected === selectedNum
          ? 'text-white'
          : 'text-white/50'
      }`}>
      {icon}
    </li>
  );
};

const NavbarMobile = ({ selected, setSelected }) => {
  const navigate = useNavigate();

  return (
    <div className="fixed bottom-0 z-10 grid h-14 w-full place-items-center rounded-t-lg bg-[#252A34]">
      <ul className="relative flex w-11/12 select-none items-center">
        <NavItem
          icon={<MdAssignment className="h-6 w-6" />}
          onClick={() => {
            setSelected(1);
            navigate('./');
          }}
          selected={selected}
          selectedNum={1}
        />
        <NavItem
          icon={<MdPlaylistAddCheck className="h-6 w-6" />}
          onClick={() => {
            setSelected(2);
            navigate('./gasket-results?completed=false');
          }}
          selected={selected}
          selectedNum={2}
        />
        <NavItem
          icon={<MdSettings className="h-6 w-6" />}
          onClick={() => {
            setSelected(3);
            navigate('./test-settings');
          }}
          selected={selected}
          selectedNum={3}
        />
        <NavSlider selected={selected} />
      </ul>
    </div>
  );
};

export default NavbarMobile;
