import { useState, useEffect, useRef } from 'react';
import { MdSaveAlt } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, ArrowBack } from '../../components';
import { handleSubmit } from './test-settings-logic';
import { fetchGasketAmount } from '../GasketOverview/gasket-overview-logic';

const TestSettings = () => {
  const testName = decodeURIComponent(
    useLocation().pathname.split('/')[2]
  );
  const navigate = useNavigate();

  const [amount, setAmount] = useState(0);

  const [state, setState] = useState({
    testName: '',
    amount: '',
  });

  useEffect(() => {
    const getAmount = async () => {
      fetchGasketAmount(testName).then(data => {
        if (!data?.amount) navigate('/dashboard');

        setAmount(data?.amount);
      });
    };

    getAmount();

    window.addEventListener('amount-change', getAmount);

    return () =>
      window.removeEventListener(
        'amount-change',
        getAmount
      );
  }, [testName, navigate]);

  const refs = {
    amount: useRef(),
  };

  return (
    <div className="flex h-full w-full flex-col items-center overflow-y-hidden pt-16 pb-14 md:pb-0">
      <div className="absolute top-2 left-4 md:left-40">
        <ArrowBack
          path="/dashboard"
          label="Back to tests"
        />
      </div>
      <p className="select-none text-center font-heading text-2xl font-light md:text-4xl">
        {testName}
        <br />
        <span className="block text-sm font-extralight opacity-70 md:text-lg">
          Adjust global settings here
        </span>
      </p>
      <div className="flex h-full w-full flex-col items-center pt-6 md:pt-10">
        <div className="relative h-full w-5/6 md:w-3/5">
          <p className="w-full select-none border-b border-white/10 pb-3 font-heading text-xl md:text-2xl">
            Settings
          </p>
          <div className="mt-6 grid-flow-col gap-10 space-y-6 lg:inline-grid lg:space-y-0">
            <Input
              label="Projectname"
              placeholder={testName}
              value={state.testName}
              handler={e =>
                setState(prev => ({
                  ...prev,
                  testName: e.target.value,
                }))
              }
              onKeyDown={e => {
                if (e.key === 'Enter')
                  refs.amount.current.focus();
              }}
            />
            <Input
              currentRef={refs.amount}
              label="Amount of gaskets"
              placeholder={amount}
              value={state.amount}
              handler={e =>
                setState(prev => ({
                  ...prev,
                  amount: e.target.value,
                }))
              }
              onKeyDown={e => {
                if (
                  e.key === 'Enter' &&
                  (state.amount !== '' ||
                    state.testName !== '')
                ) {
                  handleSubmit(
                    state,
                    setState,
                    testName,
                    amount,
                    navigate
                  );
                  e.target.blur();
                }
              }}
            />
          </div>
          <div className="absolute left-0 bottom-8 w-72 md:left-1/2 md:w-80 md:-translate-x-1/2">
            <Button
              text="Apply changes"
              variant="outline"
              disabled={
                state.amount === '' && state.testName === ''
              }
              icon={<MdSaveAlt className="h-6 w-6" />}
              onClick={() => {
                if (
                  state.amount !== '' ||
                  state.testName !== ''
                )
                  handleSubmit(
                    state,
                    setState,
                    testName,
                    amount,
                    navigate
                  );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestSettings;
