import * as Pages from './routes';
import {
  ToastPortal,
  ProtectedRoute,
  Header,
  Navbar,
} from './components';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';

import 'react-toastify/dist/ReactToastify.min.css';

const {
  Login,
  Null,
  Register,
  Tests,
  GasketOverview,
  GasketResults,
  TestSettings,
  GasketTest,
  GasketResult,
} = Pages;

const App = () => {
  return (
    <div className="fixed h-full min-h-[540px] w-full overflow-hidden bg-[#181920] text-white sm:absolute sm:overflow-auto">
      <ToastPortal />
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Null />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Header />}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <Tests />
                </ProtectedRoute>
              }
            />
            <Route path=":id" element={<Navbar />}>
              <Route
                index
                element={
                  <ProtectedRoute>
                    <GasketOverview />
                  </ProtectedRoute>
                }
              />
              <Route
                path="gasket-results"
                element={
                  <ProtectedRoute>
                    <GasketResults />
                  </ProtectedRoute>
                }
              />
              <Route
                path="test-settings"
                element={
                  <ProtectedRoute>
                    <TestSettings />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":gasket"
                element={
                  <ProtectedRoute>
                    <GasketTest />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":gasket/result"
                element={
                  <ProtectedRoute>
                    <GasketResult />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </div>
  );
};

export default App;
