import { useContext } from 'react';
import { StepperContext } from '../../context/StepperContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const Step = ({
  id,
  title,
  label,
  icon,
  last,
  forceHorizontal,
  children,
}) => {
  const { stepState, prevStep } =
    useContext(StepperContext);
  const [stepIndex] = stepState;
  const selected = stepIndex === id;
  const { width } = useWindowDimensions();

  return (
    <>
      <div
        className={`flex ${
          last ? 'md:w-auto' : 'md:w-full'
        } md:flex-col ${
          forceHorizontal
            ? `${last ? 'w-auto' : 'w-full'} flex-col`
            : 'w-auto'
        } h-full ${
          selected &&
          id !== 1 &&
          prevStep <= stepIndex &&
          !forceHorizontal &&
          'animate-slideUpSlow will-change-transform'
        } md:animate-none`}>
        {/* Step info */}
        <div className="flex w-full space-x-2">
          {/* Icon + Checkbox */}
          <div
            className={`flex ${
              !forceHorizontal && 'flex-col'
            } items-center md:flex-row`}>
            <div
              className={`grid h-6 w-6 place-items-center rounded-full shadow-md md:h-8 md:w-8 ${
                id >= stepIndex && 'p-[3px]'
              } border border-transparent transition-colors duration-300 ${
                selected
                  ? 'border-[#538DFF] bg-[#252525]'
                  : stepIndex < id
                  ? 'bg-[#252525]'
                  : 'bg-[#538DFF]'
              }`}>
              {stepIndex > id ? (
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 md:w-8"
                  viewBox="0 0 130.2 130.2">
                  <polyline
                    className="step-check-path step-check-svg"
                    fill="none"
                    stroke="white"
                    strokeWidth="10"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    points="100.2,40.2 51.5,88.8 29.8,67.5"
                  />
                </svg>
              ) : (
                icon
              )}
            </div>

            {!last && (
              <hr
                className={`${
                  selected ? 'h-full' : 'h-8'
                } ${
                  stepIndex <= id
                    ? 'bg-[#3B3F48]'
                    : 'bg-[#538DFF]'
                } top-0 my-2 w-px border-none ${
                  forceHorizontal && 'hidden'
                } transition-color duration-600 md:hidden`}
              />
            )}
          </div>

          <div
            className={`w-full space-y-2 md:flex md:items-center ${
              forceHorizontal && 'flex items-center'
            }`}>
            <p className="select-none whitespace-nowrap font-text text-sm">
              {title} <br />
              <span className="text-xs text-white/70">
                {label}
              </span>
            </p>
            {!last && (
              <hr
                className={`${
                  stepIndex <= id
                    ? 'bg-[#3B3F48]'
                    : 'bg-[#538DFF]'
                } ${
                  !forceHorizontal && 'hidden'
                } transition-color duration-600 top-0 mx-1.5 h-px w-full border-none md:mx-4 md:block`}
              />
            )}
            {selected && width < 768 && (
              <div
                className={`h-full w-full pb-3 ${
                  forceHorizontal
                    ? 'absolute top-12 left-0'
                    : 'top-8 left-12'
                }
                ${
                  (prevStep > stepIndex ||
                    prevStep === 0) &&
                  !forceHorizontal &&
                  'animate-slideDownSlow will-change-transform'
                }`}>
                {children}
              </div>
            )}
          </div>
        </div>
        {/* Children */}
      </div>
      {width >= 768 && selected && (
        <div className="absolute left-0 top-12 h-full w-full pb-3">
          {children}
        </div>
      )}
    </>
  );
};

export default Step;
