import {
  timeToSeconds,
  secondsToHms,
  zeroPad,
} from '../../helper/utils';

const parsedDate = (date, time) => {
  const dateParts = date.split('-');
  const parsedDate = new Date(
    `${
      dateParts[1]
    }/${+dateParts[0]}/${+dateParts[2]} ${time}`
  );

  return Date.parse(parsedDate);
};

export const invalidateSensitivity = (
  sensTime,
  sensDate,
  endDate,
  endTime
) => {
  if (!!sensDate && !!sensTime && !!endDate && !!endTime) {
    return (
      Math.floor(
        Math.abs(
          parsedDate(sensDate, sensTime) -
            parsedDate(endDate, endTime)
        ) / 1000
      ) >= process.env.REACT_APP_CALIBRATION_TIMEOUT
    );
  }

  return true;
};

export const invalidateStemtime = (
  startTime,
  startDate,
  endTime,
  endDate,
  stemTime
) => {
  if (
    !!startTime &&
    !!startDate &&
    !!endDate &&
    !!endTime &&
    !!stemTime
  ) {
    return (
      Math.abs(
        parsedDate(startDate, startTime) -
          parsedDate(endDate, endTime)
      ) >= Math.floor(timeToSeconds(stemTime) / 1000)
    );
  }

  return true;
};

export const getDeltaTime = (
  startTime,
  startDate,
  endTime,
  endDate
) => {
  if (
    !!startTime &&
    !!startDate &&
    !!endDate &&
    !!endTime
  ) {
    const deltaTime = Math.floor(
      Math.abs(
        (parsedDate(startDate, startTime) -
          parsedDate(endDate, endTime)) /
          1000
      )
    );

    const { hours, minutes, seconds } =
      secondsToHms(deltaTime);
    return `${zeroPad(hours, 2)}:${zeroPad(
      minutes,
      2
    )}:${zeroPad(seconds, 2)}`;
  }

  return true;
};
