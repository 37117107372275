import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { MdAccountCircle } from 'react-icons/md';
import { useAuth } from '../hooks/useAuth';
import { Modal, Button } from '../components';

const Header = () => {
  const { user, onLogout } = useAuth();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Modal
        title="Logout"
        open={open}
        setOpen={() => setOpen(false)}
        rounded="rounded-lg"
        size="w-60 h-60">
        <div className="w-full h-full pt-2 pb-12 text-center select-none text-white font-text text-sm flex flex-col items-center justify-between">
          Are you sure you want to log out?
          <div className="w-full flex items-center justify-between space-x-4">
            <Button text="Logout" onClick={onLogout} />
            <div className="w-1/2 h-full">
              <Button
                text="Cancel"
                variant="subtle"
                onClick={() => setOpen(false)}
              />
            </div>
          </div>
        </div>
      </Modal>
      <div
        className="absolute right-4 top-3 flex flex-col items-center cursor-pointer transition-colors"
        onClick={() => setOpen(!open)}>
        <MdAccountCircle
          className={`border-2 w-6 h-6 fill-white rounded-full ${
            open ? 'border-[#5168F4]' : 'border-transparent'
          }`}
        />
        <p className="font-heading text-xs opacity-70 select-none">
          {user}
        </p>
      </div>
      <Outlet />
    </>
  );
};

export default Header;
