import {
  Button,
  Dropdown,
  NumericInput,
} from '../../../components';
import { ReactComponent as TestGas } from '../../../assets/svg/testgas.svg';
import { ReactComponent as Volume } from '../../../assets/svg/volume.svg';
import { toast } from 'react-toastify';
import { FaSortAmountUp, FaRedoAlt } from 'react-icons/fa';
import {
  MdBorderTop,
  MdAccessTime,
  MdCalculate,
} from 'react-icons/md';
import {
  secondsToHms,
  zeroPad,
} from '../../../helper/utils';

export const handleSubmit = async (
  state,
  setState,
  setOpen,
  id,
  testName,
  setStepIndex,
  length
) => {
  let body = {
    ...state,
    maxAllowedLeak: parseFloat(state.maxAllowedLeak),
    id: id.current,
    testName: testName,
  };

  if (body.inspType !== 'Accumulation') {
    let {
      vHood,
      stemTime,
      minConcRaise,
      maxAllowedLeak,
      ...other
    } = body;

    body = other;
  }

  const response = await fetch(
    `${process.env.REACT_APP_DOMAIN}/api/gaskets/insert-params`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      mode: 'cors',
      body: JSON.stringify(body),
    }
  );

  const json = await response.json();

  if (response.status === 200) {
    toast.success(json.message);

    localStorage.setItem('stemTime', state?.stemTime);
    localStorage.setItem(
      'minConcRaise',
      state?.minConcRaise
    );
    localStorage.setItem(
      'maxAllowedLeak',
      state?.maxAllowedLeak
    );

    setState({
      gasketType: '',
      inspType: '',
      testGas: '',
      vHood: '',
    });

    if (id.current === length) {
      setOpen(false);
      window.dispatchEvent(new Event('update-gaskets'));
      return;
    }

    window.dispatchEvent(
      new CustomEvent('params-update', {
        detail: id.current + 1,
      })
    );
    setStepIndex(1);
  } else toast.error(json?.error);
};

const generateFirstInput = ({ gasketType }, setState) => {
  const handleChange = value =>
    setState(prev => ({ ...prev, gasketType: value }));

  return {
    component: (
      <Dropdown
        placeholder="Gasket type"
        editable
        autoFocus
        initial={gasketType}
        onChange={handleChange}>
        <Dropdown.Item text="Stem Seal (static)" />
        <Dropdown.Item text="Stem Seal (dynamic)" />
        <Dropdown.Item text="Stuffing box" />
        <Dropdown.Item text="Bonnet gasket" />
        <Dropdown.Item text="Body gasket" />
        <Dropdown.Item text="Trunnion gasket" />
      </Dropdown>
    ),
    disabled: gasketType === '',
  };
};

const generateSecondInput = ({ inspType }, setState) => {
  const handleChange = value =>
    setState(prev => ({ ...prev, inspType: value }));

  return {
    component: (
      <Dropdown
        placeholder="Select Inspection Method"
        autoFocus
        initial={inspType}
        onChange={handleChange}>
        <Dropdown.Item text="Accumulation" />
        <Dropdown.Item text="Direct Sniffing" />
        <Dropdown.Item text="Vacuum Sniffing" />
        <Dropdown.Item text="Concentration Sniffing" />
      </Dropdown>
    ),
    disabled: inspType === '',
  };
};

const generateThirdInput = (
  state,
  setState,
  refs,
  setStepIndex,
  setOpen,
  id,
  testName,
  length
) => {
  const validate = () => {
    if (
      state.testGas === '' ||
      parseFloat(state.testGas) <= 0 ||
      parseFloat(state.testGas) > 100
    )
      return true;
    if (
      state.inspType === 'Accumulation' &&
      (state.vHood === '' || parseFloat(state.vHood) <= 0)
    )
      return true;
  };

  const disabled = validate();

  return {
    component: (
      <div className="flex-col items-center space-y-1 text-left md:flex md:space-y-4">
        <NumericInput
          autoFocus
          currRef={refs.testGas}
          type="number"
          placeholder="Number"
          label="Concentration Testgas"
          leftIcon={<TestGas />}
          rightSection="%"
          enterKeyHint={
            state.inspType === 'Accumulation'
              ? 'continue'
              : 'go'
          }
          value={state.testGas}
          handler={e =>
            setState(prev => ({
              ...prev,
              testGas: e.target.value,
            }))
          }
          onKeyDown={e => {
            if (e.key === 'Enter' && e.target.value !== '')
              if (state.inspType === 'Accumulation')
                refs.vHood.current.focus();
              else {
                e.target.blur();
                handleSubmit(
                  state,
                  setState,
                  setOpen,
                  id,
                  testName,
                  setStepIndex,
                  length
                );
              }
          }}
        />
        {state.inspType === 'Accumulation' && (
          <NumericInput
            currRef={refs.vHood}
            type="number"
            placeholder="Number"
            label="Volume Hood"
            leftIcon={<Volume />}
            rightSection="liters"
            value={state.vHood}
            handler={e =>
              setState(prev => ({
                ...prev,
                vHood: e.target.value,
              }))
            }
            onKeyDown={e => {
              if (e.key === 'Enter' && !disabled) {
                setStepIndex(4);
              }
            }}
          />
        )}
      </div>
    ),
    disabled: disabled,
  };
};

const generateFourthInput = (state, setState, refs) => {
  const validate = () => {
    if (
      state.minConcRaise === '' ||
      parseFloat(state.minConcRaise) <= 0
    )
      return true;

    if (
      state.maxAllowedLeak === '' ||
      parseFloat(state.maxAllowedLeak) <= 0
    )
      return true;
  };

  const disabled = validate();

  const calculateStemTime = () => {
    const pAtm = 1030;
    const stemTime =
      (2 *
        (state.minConcRaise / 1000000) *
        pAtm *
        state.vHood) /
      (state.maxAllowedLeak * (state.testGas / 100));

    if (Math.floor(stemTime) <= 0) return null;

    const { hours, minutes, seconds } =
      secondsToHms(stemTime);

    return `${zeroPad(hours, 2)}:${zeroPad(
      minutes,
      2
    )}:${zeroPad(Math.floor(seconds), 2)}`;
  };

  return {
    component: (
      <div className="flex-col items-center space-y-2 text-left md:flex md:space-y-4">
        {state.stemTime !== '' ? (
          <div>
            <p className="mb-1.5 font-heading text-sm opacity-70">
              Stemtime
            </p>
            <div className="flex h-10 w-72 rounded-lg border-2 border-[#292B2F] bg-[#181920] md:w-80">
              <div className="mx-3 grid h-full w-10 place-items-center opacity-70">
                <MdAccessTime className="h-6 w-6" />
              </div>
              <div className="-mt-0.5 flex h-10 w-full items-center justify-center border-2 border-[#292B2F] px-3 font-heading text-sm">
                {state.stemTime}
              </div>
              <p className="mx-3 grid h-full w-32 select-none place-items-center font-heading font-light opacity-70">
                hh:mm:ss
              </p>
            </div>
          </div>
        ) : (
          <>
            <NumericInput
              autoFocus
              type="number"
              label="Minimum concentration raise"
              rightSection="ppm.v"
              placeholder="Number"
              leftIcon={
                <FaSortAmountUp className="h-4 w-4" />
              }
              value={state.minConcRaise}
              handler={e =>
                setState(prev => ({
                  ...prev,
                  minConcRaise: e.target.value,
                }))
              }
              onKeyDown={e => {
                if (
                  e.key === 'Enter' &&
                  e.target.value !== ''
                ) {
                  refs.maxAllowedLeak.current.focus();
                }
              }}
            />
            <NumericInput
              currRef={refs.maxAllowedLeak}
              type="text"
              label="Maximum allowable leakrate"
              leftIcon={<MdBorderTop className="h-6 w-6" />}
              rightSection="mbar.l/s"
              placeholder="Number"
              value={state.maxAllowedLeak}
              handler={e =>
                setState(prev => ({
                  ...prev,
                  maxAllowedLeak: e.target.value,
                }))
              }
              onKeyDown={e => {
                if (e.key === 'Enter' && !disabled) {
                  e.target.blur();

                  const stemTime = calculateStemTime();

                  if (stemTime === null)
                    return toast.error(
                      'Stemtime is cannot be zero or negative'
                    );

                  setState(prev => ({
                    ...prev,
                    stemTime: stemTime,
                  }));
                }
              }}
            />
          </>
        )}

        <Button
          text={
            state.stemTime !== ''
              ? 'Recalculate stemtime'
              : 'Calculate stemtime'
          }
          variant="outline"
          icon={
            state.stemTime !== '' ? (
              <FaRedoAlt className="h-4 w-4" />
            ) : (
              <MdCalculate className="h-6 w-6" />
            )
          }
          disabled={state.stemTime === '' && disabled}
          onClick={() => {
            if (state.stemTime === '') {
              const stemTime = calculateStemTime();

              if (stemTime === null)
                return toast.error(
                  'Stemtime is cannot be zero or negative'
                );

              setState(prev => ({
                ...prev,
                stemTime: stemTime,
              }));
            } else
              setState(prev => ({
                ...prev,
                stemTime: '',
              }));
          }}
        />
      </div>
    ),
    disabled: state.stemTime === '',
  };
};

export const generateInput = (
  stepNumber,
  state,
  setState,
  refs,
  setStepIndex,
  setOpen,
  id,
  testName,
  length
) => {
  switch (stepNumber) {
    case 1:
      return generateFirstInput(state, setState);
    case 2:
      return generateSecondInput(state, setState);
    case 3:
      return generateThirdInput(
        state,
        setState,
        refs,
        setStepIndex,
        setOpen,
        id,
        testName,
        length
      );
    case 4:
      return generateFourthInput(state, setState, refs);
    default:
      break;
  }
};

export const fetchGasketParams = async (id, testName) => {
  const response = await fetch(
    `${
      process.env.REACT_APP_DOMAIN
    }/api/gaskets/fetch-params?${new URLSearchParams({
      id: id.current,
      testName: encodeURIComponent(testName),
    })}`,
    {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
    }
  );

  if (response.status === 200) {
    const json = await response.json();

    return json;
  }
};
