const addSensitivity = async body => {
  const response = await fetch(
    `${process.env.REACT_APP_DOMAIN}/api/sensitivity/add-sensitivity`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      mode: 'cors',
      body: JSON.stringify(body),
    }
  );

  const json = await response.json();

  return json;
};

export default addSensitivity;
