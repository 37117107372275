import { useState } from 'react';
import { MdSearch } from 'react-icons/md';

const SearchBar = ({ value, setValue }) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={`w-full relative pr-2 h-10 overflow-x-hidden`}>
      <input
        className="hidden md:block bg-transparent font-heading text-sm placeholder-white/50 w-full h-full px-3 outline-none focus:outline-none border-2 border-[#292B2F] rounded-lg transition-all focus:border-[#5168F4]"
        value={value}
        placeholder="Search"
        onChange={e => setValue(e.target.value)}
        onFocus={e => e.target.select()}
      />
      <MdSearch className="hidden md:block fill-white/70 p-1 h-8 w-8 rounded-full transition-all absolute right-3 top-1/2 -translate-y-1/2 z-10" />
      {open && (
        <input
          className="md:hidden bg-[#181920] font-heading text-sm placeholder-white/50 w-full h-full px-3 outline-none focus:outline-none border-2 border-[#292B2F] rounded-lg transition-all focus:border-[#5168F4] animate-appear"
          value={value}
          autoFocus
          onChange={e => setValue(e.target.value)}
          onFocus={e => e.target.select()}
        />
      )}
      <MdSearch
        className="md:hidden fill-white/70 p-1 h-8 w-8 rounded-full transition-all hover:bg-white/10 hover:fill-white cursor-pointer absolute right-3 top-1/2 -translate-y-1/2 z-10"
        onClick={() => setOpen(!open)}
      />
    </div>
  );
};

export default SearchBar;
