import { createPortal } from 'react-dom';
import { useEffect, useRef, useState } from 'react';
import { MdClose } from 'react-icons/md';
import useOnClickOutside from '../hooks/useOnClickOutside';

const Drawer = ({
  title,
  height,
  rounded,
  open,
  setOpen,
  children,
}) => {
  const [mounted, setMounted] = useState(false);
  const drawerRef = useRef();

  useOnClickOutside(drawerRef, setOpen);

  useEffect(() => {
    let timeout;

    if (!open)
      timeout = setTimeout(() => setMounted(false), 125);
    else setMounted(true);

    return () => clearTimeout(timeout);
  }, [open]);

  if (!open && !mounted) return null;

  return createPortal(
    <div className="fixed z-50 grid h-full w-full place-items-center text-white">
      <div className="absolute z-0 h-full w-full bg-opacity-10 backdrop-brightness-50" />
      <div
        ref={height !== 'h-full' ? drawerRef : null}
        className={`bg-[#252A34]  ${
          open ? 'animate-slideUp' : 'animate-slideDown'
        }
           absolute bottom-0 z-10 w-full px-3 py-2 drop-shadow-lg ${height} ${rounded}`}>
        <div className="mb-2 flex w-full items-center justify-between overflow-y-auto border-b border-white/10 pb-1 text-white">
          <p className="bottom-0 select-none font-heading text-xl">
            {title}
          </p>
          <MdClose
            className="h-8 w-8 cursor-pointer rounded-full fill-white/70 p-2 transition-all hover:bg-white/10 hover:fill-white active:translate-y-0.5"
            onClick={setOpen}
          />
        </div>
        {height === 'h-full' ? (
          <div className="h-full overflow-y-auto overflow-x-hidden">
            {children}
          </div>
        ) : (
          children
        )}
      </div>
    </div>,
    document.getElementById('modal-portal')
  );
};

export default Drawer;
