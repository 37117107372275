import {
  zeroPad,
  secondsToHms,
} from '../../../../helper/utils';
import {
  insertFirstReading,
  insertGasketTest,
} from '../../../../api/gaskets';
import { toast } from 'react-toastify';

export const getDynamicProps = inspType => {
  switch (inspType) {
    case 'Accumulation':
      return { title: 'Reading 1' };
    case 'Concentration Sniffing':
      return { title: 'Detector reading environment' };
    default:
      return { title: 'Detector reading background' };
  }
};

export const validate = (
  inspType,
  readingLeak1,
  readingLeak2,
  isSecondReading
) => {
  if (readingLeak1 === '' || parseFloat(readingLeak1) <= 0)
    return true;

  if (
    ((isSecondReading && inspType === 'Accumulation') ||
      inspType !== 'Accumulation') &&
    (readingLeak2 === '' || parseFloat(readingLeak2) <= 0)
  )
    return true;

  return false;
};

export const handleFirstStep = async ({
  readingLeak1,
  id,
  testName,
  setStepIndex,
  setState,
}) => {
  const dateObj = new Date();
  const date = dateObj.toLocaleDateString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  const time = zeroPad(dateObj.toLocaleTimeString());

  const body = {
    readingLeak1: parseFloat(readingLeak1),
    startDate: date,
    startTime: time,
    id: id,
    testName: testName,
  };

  const response = await insertFirstReading(body);

  if (response?.error) toast.error(response.error);
  else if (response?.message) {
    toast.success(response.message);

    setState(prev => ({
      ...prev,
      readingLeak1: parseFloat(readingLeak1),
      startDate: date,
      startTime: time,
    }));

    setStepIndex(prev => prev + 1);
  }
};

const calculateLeakrate = ({
  inspType,
  readingLeak1,
  readingLeak2,
  sensitivity,
  vHood,
  deltaTime,
  testGas,
}) => {
  let finalLeakValue, concRaise;

  if (inspType === 'Accumulation') {
    concRaise =
      parseFloat(sensitivity) *
      (parseFloat(readingLeak2) - parseFloat(readingLeak1));

    const parsedTime = deltaTime
      .split(':')
      .reduce((acc, time) => 60 * acc + +time);

    finalLeakValue =
      (parseFloat(concRaise) * 1030 * parseFloat(vHood)) /
      (parsedTime * (parseFloat(testGas) / 100));
  } else
    finalLeakValue =
      parseFloat(sensitivity) *
      ((parseFloat(readingLeak2) -
        parseFloat(readingLeak1)) /
        (parseFloat(testGas) / 100));

  if (inspType === 'Concentration Sniffing')
    finalLeakValue = parseFloat(`${finalLeakValue}e+6`);

  return {
    finalLeakrate: finalLeakValue.toExponential(2),
    concRaise: concRaise,
  };
};

export const handleSubmit = async ({
  inspType,
  readingLeak1,
  readingLeak2,
  sensitivity,
  vHood,
  deltaTime,
  testGas,
  id,
  testName,
  refValue,
  user,
  navigate,
  sensAuthor,
  sensDate,
  sensTime,
  sensReadingRefLeak,
  sensReadingBg,
}) => {
  const dateObj = new Date();
  const date = dateObj.toLocaleDateString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  const time = zeroPad(dateObj.toLocaleTimeString());
  const { finalLeakrate, concRaise } = calculateLeakrate({
    inspType,
    readingLeak1,
    readingLeak2,
    sensitivity,
    vHood,
    deltaTime,
    testGas,
  });

  if (
    parseFloat(finalLeakrate) === Infinity ||
    parseFloat(finalLeakrate) < 0
  )
    return toast.error(
      'Final leakrate cannot be negative or infinite!'
    );

  let body = {
    id: id,
    testName: testName,
    author: user,
    readingLeak1: parseFloat(readingLeak1),
    readingLeak2: parseFloat(readingLeak2),
    endDate: date,
    endTime: time,
    finalLeakValue: parseFloat(finalLeakrate),
    inspType: inspType,
    sensitivity: sensitivity,
    sensAuthor: sensAuthor,
    refValue: parseFloat(refValue),
    sensDate: sensDate,
    sensTime: sensTime,
    sensReadingRefLeak: parseFloat(sensReadingRefLeak),
    sensReadingBg: parseFloat(sensReadingBg),
  };

  if (inspType === 'Accumulation')
    body = {
      ...body,
      concRaise: concRaise,
      deltaTime:
        deltaTime.length === 5
          ? deltaTime + ':00'
          : deltaTime,
    };
  else localStorage.setItem('readingBg', readingLeak1);

  const response = await insertGasketTest(body);

  if (response?.error) toast.error(response.error);
  else if (response?.message) {
    toast.success(response.message);

    navigate('./result?continue=true');
  }
};

export const calculateDiffTime = (startTime, startDate) => {
  const dateParts = startDate.split('-');
  const parsedDate = new Date(
    `${
      dateParts[1]
    }/${+dateParts[0]}/${+dateParts[2]} ${startTime}`
  );

  const newTime =
    Date.parse(new Date()) - Date.parse(parsedDate);

  const { hours, minutes, seconds } = secondsToHms(
    Math.floor(newTime / 1000)
  );

  return `${zeroPad(hours, 2)}:${zeroPad(
    minutes,
    2
  )}:${zeroPad(seconds, 2)}`;
};
