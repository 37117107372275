import { useRef, useState } from 'react';
import { ReactComponent as Logo } from '../../assets/svg/logo.svg';
import Banner from '../../assets/images/banner.jpg';
import { MdAccountCircle, MdLock } from 'react-icons/md';
import { Input, Button } from '../../components';
import { handleSubmit } from './login-logic';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({
    username: null,
    password: null,
  });

  const passwordRef = useRef();
  const navigate = useNavigate();
  const { onLogin } = useAuth();

  return (
    <div className="flex h-full w-full">
      {/* Banner */}
      <img
        className="hidden w-1/2 select-none object-cover md:block"
        src={Banner}
        alt="Testing valves"
      />
      {/* Right section */}
      <div className="flex h-full w-full flex-col items-center justify-between pt-6 pb-10">
        {/* Header Text + Logo */}
        <div className="flex items-center">
          <Logo />
          <hr className="ml-7 h-10 w-px rounded-full border-none bg-white/50" />
          <p className="select-none pl-4 font-heading text-white/70">
            ITIS Cloud Testing
          </p>
        </div>
        {/* Text + form */}
        <div className="space-y-10 md:flex md:h-1/3 md:flex-col md:justify-between">
          <p className="select-none text-center font-heading text-2xl font-light md:text-4xl">
            Welcome back! <br />
            <span className="block text-sm font-extralight opacity-70 md:text-lg">
              Please sign in to your account
            </span>
          </p>
          {/* Form */}
          <div>
            <div className="space-y-6">
              <Input
                placeholder="Username"
                autoFocus
                invalid={error.username}
                value={username}
                handler={e => setUsername(e.target.value)}
                enterKeyHint="next"
                leftIcon={
                  <MdAccountCircle className="h-5 w-5 fill-white" />
                }
                onKeyDown={e => {
                  if (e.key === 'Enter')
                    passwordRef.current.focus();
                }}
              />
              <Input
                type="password"
                placeholder="Password"
                invalid={error.password}
                currentRef={passwordRef}
                value={password}
                handler={e => setPassword(e.target.value)}
                enterKeyHint="go"
                leftIcon={
                  <MdLock className="h-5 w-5 fill-white" />
                }
                onKeyDown={e => {
                  if (e.key === 'Enter')
                    handleSubmit(
                      username,
                      password,
                      setError,
                      navigate,
                      onLogin
                    );
                }}
              />
            </div>
            <div className="flex w-full flex-row-reverse">
              <p className="mt-3 cursor-pointer select-none font-heading text-sm font-extralight opacity-50 transition-opacity hover:opacity-100">
                Forgot Password?
              </p>
            </div>
          </div>
        </div>
        <div className="w-72 space-y-8 md:w-80">
          <Button
            text="Sign in"
            onClick={() =>
              handleSubmit(
                username,
                password,
                setError,
                navigate,
                onLogin
              )
            }
          />
          <p className="w-full select-none text-center font-heading text-sm font-light">
            Don’t have an account?{' '}
            <span
              className="cursor-pointer font-normal text-[#5168F4]"
              onClick={() => navigate('/register')}>
              Sign up
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
