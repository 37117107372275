import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import TestListDesktop from './components/TestListDesktop';
import TestListMobile from './components/TestListMobile';
import {
  getUnfinishedTests,
  getTestsByDate,
  getFinishedTests,
  filterTests,
} from './testlist-logic';

const Tests = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [tests, setTests] = useState({
    finished: [],
    unfinished: [],
    loading: true,
  });

  const [search, setSearch] = useState({
    unfinished: '',
    finished: '',
  });

  useEffect(() => {
    const generateTests = async () => {
      setTests(oldState => ({
        ...oldState,
        loading: true,
      }));

      const unfinishedTests = await getUnfinishedTests();
      const finishedTests = await getFinishedTests();
      const unfinishedTestsSorted =
        getTestsByDate(unfinishedTests);
      const finishedTestsSorted = getTestsByDate(
        finishedTests,
        1
      );

      setTests({
        finished: finishedTestsSorted,
        unfinished: unfinishedTestsSorted,
        loading: false,
      });
    };

    generateTests();

    window.addEventListener('update-tests', generateTests);

    return () =>
      window.removeEventListener(
        'update-tests',
        generateTests
      );
  }, [navigate]);

  useEffect(() => {
    const refresh = async () => {
      const unfinishedTests = await getUnfinishedTests();
      const finishedTests = await getFinishedTests();
      const unfinishedTestsSorted =
        getTestsByDate(unfinishedTests);
      const finishedTestsSorted = getTestsByDate(
        finishedTests,
        1
      );

      if (
        JSON.stringify(finishedTestsSorted) !==
          JSON.stringify(tests.finished) ||
        JSON.stringify(unfinishedTestsSorted) !==
          JSON.stringify(tests.unfinished)
      )
        setTests(oldState => ({
          ...oldState,
          finished: finishedTestsSorted,
          unfinished: unfinishedTestsSorted,
        }));
    };

    const interval = setInterval(refresh, 10000);

    return () => clearInterval(interval);
  }, [tests]);

  const { width } = useWindowDimensions();
  const [open, setOpen] = useState(false);

  return (
    <div className="flex h-full w-full flex-col items-center pt-16">
      {/* Header title here */}
      <p className="select-none text-center font-heading text-2xl font-light md:text-4xl">
        Welcome {user}
        <br />
        <span className="block text-sm font-extralight opacity-70 md:text-lg">
          Please create a new test, or choose an existing
          one!
        </span>
      </p>
      {width <= 768 ? (
        <TestListMobile
          tests={{
            unfinished: filterTests(
              tests.unfinished,
              search.unfinished
            ),
            finished: filterTests(
              tests.finished,
              search.finished
            ),
            loading: tests.loading,
          }}
          search={search}
          setSearch={setSearch}
          open={open}
          setOpen={setOpen}
        />
      ) : (
        <TestListDesktop
          tests={{
            unfinished: filterTests(
              tests.unfinished,
              search.unfinished
            ),
            finished: filterTests(
              tests.finished,
              search.finished
            ),
            loading: tests.loading,
          }}
          search={search}
          setSearch={setSearch}
          open={open}
          setOpen={setOpen}
        />
      )}
    </div>
  );
};

export default Tests;
