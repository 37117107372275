import { MdEdit, MdDoneAll } from 'react-icons/md';
import { Card } from '../../components';
import { searchTermMatches } from '../../helper/utils';

export const fetchGasketAmount = async name => {
  const response = await fetch(
    `${
      process.env.REACT_APP_DOMAIN
    }/api/gaskets/unfinished-gaskets/${encodeURIComponent(
      name
    )}`,
    {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
    }
  );

  if (response.status === 200) {
    const json = await response.json();

    return json;
  }
};

export const generateGasketList = async (
  { amount, gasketListWithParams },
  setGasketList,
  handleParams,
  handleTest
) => {
  let list = [];

  for (let i = 1; i <= amount; i++) {
    let name = `Gasket ${i}`;
    let hasParams,
      completed = false;
    let id;

    if (gasketListWithParams.length !== 0) {
      gasketListWithParams.every(o => {
        if (o?.id === i) {
          name = o.name;
          id = o.id;
          hasParams = true;

          if (o.completed) completed = true;

          return false;
        }

        return true;
      });
    }

    if (!id) id = i;

    if (!completed) {
      list = [
        ...list,
        <div
          name={name}
          key={i}
          className="w-auto pb-4 pr-2 md:pb-0">
          <Card
            size="h-12 md:h-14"
            color="bg-[#5168F4]"
            disabled={!hasParams}
            leftSection={
              <p className="font-heading text-sm font-semibold">
                {name}
              </p>
            }
            rightIcon={
              <div
                className="group relative cursor-pointer"
                onClick={() => handleParams(id)}>
                <MdEdit className="mr-2 h-8 w-8 rounded-full p-1 transition-all group-hover:bg-white/10" />
                {hasParams && (
                  <MdDoneAll className="absolute bottom-1/3 -right-0.5 h-5 w-5 translate-y-1/3 fill-[#51F47F]" />
                )}
              </div>
            }
            onClick={() => {
              if (hasParams) handleTest(id);
            }}
          />
        </div>,
      ];
    }
  }

  setGasketList(list);

  return;
};

export const filterGaskets = (gasketList, searchTerm) => {
  if (gasketList.length === 0) return [];
  else {
    const arr = gasketList.filter(e => {
      if (searchTermMatches(e.props.name, searchTerm))
        return e;
      return null;
    });
    return arr;
  }
};
