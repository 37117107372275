const ResultViewbox = ({ children, label, oneResult }) => {
  return (
    <div>
      <span className="mb-1.5 font-heading text-sm opacity-70">
        {label}
      </span>
      <div className="w-72 rounded-lg border border-[#292B2F] px-4 md:w-80">
        {children.map((child, index) => {
          if (index < children.length && index > 0)
            return (
              <div key={index}>
                {!oneResult && (
                  <hr className="h-px w-full border-none bg-[#292B2F]" />
                )}
                {child}
              </div>
            );
          return child;
        })}
      </div>
    </div>
  );
};

export default ResultViewbox;
