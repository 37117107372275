import { MdWarning } from 'react-icons/md';
import { Modal, Button } from '../../../components';
import { useAuth } from '../../../hooks/useAuth';
import { deleteCard } from '../testlist-logic';
import { toast } from 'react-toastify';

const DeleteModal = ({
  open,
  setOpen,
  name,
  author,
  pdf,
  finished,
}) => {
  const { onRefresh } = useAuth();
  const user = sessionStorage.getItem('user');
  return (
    <Modal
      title="Delete Test?"
      open={open}
      setOpen={() => setOpen(false)}
      canClose={false}
      rounded="rounded-lg"
      size="w-60 h-80">
      <div className="flex h-full w-full flex-col items-center justify-between pt-4 pb-12">
        <p className="text-center font-text text-sm text-white">
          Are you sure you want to delete test{' '}
          <span className="text-[#A3ADFF]">{name}</span>{' '}
          created by{' '}
          <span className="text-[#A3ADFF]">
            {author === user ? 'You' : author}
          </span>
          ?
          <br />
          <br />
          <span className="text-xs font-light opacity-70">
            This cannot be undone!
          </span>
        </p>
        {(!finished || (finished && !pdf)) && (
          <div className="flex h-16 w-full items-center space-x-3 rounded-lg border border-[#3A3F48] px-2">
            <MdWarning className="h-6 w-6 fill-[#D85656]" />
            <p className="font-text text-xs text-[#D85656]">
              {!pdf
                ? 'No PDF has been downloaded yet!'
                : 'This test has not yet been completed!'}
            </p>
          </div>
        )}
        <div className="flex w-full items-center justify-between space-x-4">
          <Button
            text="Delete Test"
            color="bg-[#D85656]"
            hover="hover:bg-[#DF7C7C]"
            onClick={async () => {
              await onRefresh();
              const response = await deleteCard(name);

              if (response?.message)
                toast.success(response.message);
              if (response?.error)
                toast.error(response.error);

              window.dispatchEvent(
                new Event('update-tests')
              );

              setOpen(false);
            }}
          />
          <div className="h-full w-1/2">
            <Button
              text="Cancel"
              variant="subtle"
              onClick={() => setOpen(false)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
