import { useContext, useRef } from 'react';
import {
  Modal,
  Button,
  Stepper,
} from '../../../components';
import { StepperContext } from '../../../context/StepperContext';
import {
  MdDriveFileRenameOutline,
  MdTune,
  MdList,
  MdOutlineAccessTime,
} from 'react-icons/md';
import {
  generateInput,
  handleSubmit,
} from './gasket-params-logic';

const Step = ({
  title,
  state,
  setState,
  setOpen,
  id,
  testName,
  length,
  offset,
}) => {
  const { stepState } = useContext(StepperContext);
  const [stepIndex, setStepIndex] = stepState;

  const refs = {
    testGas: useRef(),
    vHood: useRef(),
    maxAllowedLeak: useRef(),
  };

  const { component, disabled } = generateInput(
    stepIndex,
    state,
    setState,
    refs,
    setStepIndex,
    setOpen,
    id,
    testName,
    length
  );

  return (
    <div className="absolute flex h-full w-full flex-col items-center justify-center pb-52">
      <div className="z-10 flex w-full flex-col items-center text-center">
        <p className="my-10 select-none font-heading text-base font-light opacity-70">
          {title}
        </p>
        {component}
      </div>

      <div className="absolute bottom-36 z-0 flex w-3/5 max-w-xs items-center justify-between space-x-4">
        <Button
          text={
            (stepIndex !== 4 &&
              state.inspType === 'Accumulation') ||
            (stepIndex !== 3 &&
              state.inspType !== 'Accumulation')
              ? 'Continue'
              : 'Confirm'
          }
          disabled={disabled}
          onClick={() => {
            if (
              (stepIndex !== 4 &&
                state.inspType === 'Accumulation') ||
              (stepIndex !== 3 &&
                state.inspType !== 'Accumulation')
            )
              setStepIndex(stepIndex + 1);
            else
              handleSubmit(
                state,
                setState,
                setOpen,
                id,
                testName,
                setStepIndex,
                length
              );
          }}
        />
        <div className="h-full w-1/2">
          <Button
            text="Back"
            variant="subtle"
            disabled={id?.current - offset === 1}
            onClick={() => {
              if (stepIndex > 1)
                setStepIndex(stepIndex - 1);
              else if (
                id?.current &&
                id.current - offset > 1
              )
                window.dispatchEvent(
                  new CustomEvent('params-update', {
                    detail: id.current - 1,
                  })
                );
            }}
          />
        </div>
      </div>
    </div>
  );
};
const GasketParamsDesktop = ({
  open,
  setOpen,
  id,
  testName,
  initialParams,
  setInitialParams,
  amount,
  offset,
}) => {
  return (
    <Modal
      title="Insert parameters"
      rounded="rounded-lg"
      open={open}
      setOpen={() => setOpen(false)}
      size="w-1/2 min-w-fit h-4/5">
      <label
        htmlFor="progress"
        className="font-heading text-sm text-white/70">
        Gasket {id?.current} - {amount}
      </label>
      <div
        id="progress"
        className="relative mt-2 h-0.5 w-full rounded-full bg-gray-500">
        <div
          className="h-full w-full origin-left rounded-l-full bg-blue-400 transition-transform"
          style={{
            transform: `scaleX(${
              id?.current ? (id?.current / amount) * 100 : 0
            }%)`,
            borderTopRightRadius:
              (id?.current / amount) * 100 === 100
                ? '9999px'
                : 0,
            borderBottomRightRadius:
              (id?.current / amount) * 100 === 100
                ? '9999px'
                : 0,
          }}
        />
      </div>
      <div className="h-full px-8 pt-6 text-white">
        <Stepper
          length={
            initialParams.inspType === 'Accumulation'
              ? 4
              : 3
          }>
          <Stepper.Step
            title="Step 1"
            label="Gasket Type"
            icon={
              <MdDriveFileRenameOutline className="h-full w-full" />
            }>
            <Step
              title="Insert the gasket type you wish to inspect"
              state={initialParams}
              setState={setInitialParams}
              id={id}
              length={amount}
              offset={offset}
            />
          </Stepper.Step>
          <Stepper.Step
            title="Step 2"
            label="Method"
            icon={<MdTune className="h-full w-full" />}>
            <Step
              title="Choose between the four inspection methods"
              state={initialParams}
              setState={setInitialParams}
            />
          </Stepper.Step>
          <Stepper.Step
            title="Step 3"
            label="Testing parameters"
            icon={<MdList className="h-full w-full" />}>
            <Step
              title="Insert the necessary calibration and testing parameters here"
              state={initialParams}
              setState={setInitialParams}
              setOpen={setOpen}
              id={id}
              testName={testName}
              length={amount}
            />
          </Stepper.Step>
          {initialParams.inspType === 'Accumulation' ? (
            <Stepper.Step
              title="Step 4"
              label="Stem time"
              icon={
                <MdOutlineAccessTime className="h-full w-full" />
              }>
              <Step
                title="Insert or calculate the stem time here"
                state={initialParams}
                setState={setInitialParams}
                setOpen={setOpen}
                id={id}
                testName={testName}
                length={amount}
              />
            </Stepper.Step>
          ) : (
            <></>
          )}
        </Stepper>
      </div>
    </Modal>
  );
};

export default GasketParamsDesktop;
