const getStyles = (variant, color, hover) => {
  switch (variant) {
    case 'outline':
      return 'border-2 border-[#5168F4] hover:bg-[#5168F4]/20 text-[#5168F4]';
    case 'subtle':
      return 'text-[#5168F4] hover:bg-[#5168F4]/20';
    default: // Filled
      return `text-white ${hover} ${color} shadow-md`;
  }
};

const Button = ({
  text,
  icon,
  variant,
  color = 'bg-[#5168F4]',
  hover = 'hover:bg-[#6D82FF]',
  onClick,
  disabled,
}) => {
  return (
    <button
      className={`h-10 w-full rounded-lg font-text text-sm outline-none ring-blue-500 ring-offset-2 ring-offset-blue-400 transition-all focus:outline-none focus:ring-2 ${
        !disabled
          ? 'cursor-pointer active:translate-y-0.5'
          : 'cursor-not-allowed opacity-50'
      } relative flex ${getStyles(variant, color, hover)}`}
      disabled={disabled}
      onClick={onClick}>
      {icon && (
        <div className="grid h-full place-items-center px-2">
          {icon}
        </div>
      )}
      <p className="absolute grid h-full w-full select-none place-items-center">
        {text}
      </p>
    </button>
  );
};

export default Button;
