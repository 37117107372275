import { ArrowBack, Stepper } from '../../components';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { MdDriveFileRenameOutline } from 'react-icons/md';
import { ReactComponent as FlangeIcon } from '../../assets/svg/flange.svg';
import { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import {
  getGasketParams,
  getGasketTest,
} from '../../api/gaskets';
import { getSensitivity } from '../../api/sensitivity';
import { TestingStep, CalibrationStep } from './components';

const GasketTest = () => {
  const testName = decodeURIComponent(
    useLocation().pathname.split('/')[2]
  );
  const { gasket } = useParams();
  const { width } = useWindowDimensions();
  const { user } = useAuth();

  const [calibration, setCalibration] = useState({
    refValue: '',
    readingBg: '1.00e-7',
    readingRefLeak: '',
    sensitivity: '',
    endTime: '',
    endDate: '',
    author: '',
  });

  const [testing, setTesting] = useState({
    readingLeak1: '',
    readingLeak2: '',
    finalLeak: '',
    concRaise: '',
    startDate: '',
    startTime: '',
    endTime: '',
    endDate: '',
    author: '',
  });

  const [testCounter, setTestCounter] =
    useState('00:00:00');

  const [gasketParams, setGasketParams] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getGasketParams(gasket, testName).then(params => {
      if (!params)
        return navigate(`/dashboard/${testName}`);

      setGasketParams(params);

      getSensitivity(
        testName,
        params.inspectionType,
        parseFloat(params.refValue)
      ).then(sensitivity => {
        let refValue;

        if (sensitivity?.refValue)
          params.inspectionType === 'Direct Sniffing' ||
          'Vacuum Sniffing'
            ? (refValue = parseFloat(
                sensitivity.refValue
              ).toExponential(1))
            : (refValue = parseFloat(sensitivity.refValue));

        if (sensitivity) {
          setCalibration({
            readingBg: parseFloat(
              sensitivity.readingBg
            ).toExponential(1),
            readingRefLeak: parseFloat(
              sensitivity.readingRefLeak
            ).toExponential(1),
            sensitivity: sensitivity.sensitivity,
            endTime: sensitivity.endTime,
            endDate: sensitivity.endDate,
            author: sensitivity.author,
            refValue: refValue || '',
          });
        }

        getGasketTest(gasket, testName).then(data => {
          if (data) {
            setTesting({
              readingLeak1: data.readingLeak1
                ? parseFloat(
                    data.readingLeak1
                  ).toExponential(1)
                : params.inspectionType === 'Accumulation'
                ? ''
                : localStorage.getItem('readingBg') || '',
              readingLeak2: data.readingLeak2
                ? parseFloat(
                    data.readingLeak2
                  ).toExponential(1)
                : '',
              finalLeak: data.finalLeakValue || '',
              concRaise: data.concRaise || '',
              startDate: data.startDate || '',
              startTime: data.startTime || '',
              endTime: data.endTime || '',
              endDate: data.endDate || '',
              author: data.author || '',
            });

            if (data?.refValue)
              params.inspectionType === 'Direct Sniffing' ||
              'Vacuum Sniffing'
                ? (refValue = parseFloat(
                    data.refValue
                  ).toExponential(1))
                : (refValue = parseFloat(data.refValue));

            if (data?.sensitivity)
              setCalibration({
                readingBg: parseFloat(
                  data.sensReadingBg
                ).toExponential(1),
                readingRefLeak: parseFloat(
                  data.sensReadingRefLeak
                ).toExponential(1),
                sensitivity: data.sensitivity,
                endTime: data.sensTime,
                endDate: data.sensDate,
                author: data.sensAuthor,
                refValue: refValue,
              });

            setLoading(false);
          }
        });
      });
    });

    // eslint-disable-next-line
  }, [gasket, testName]);

  return loading ? (
    <></>
  ) : (
    <div className="flex h-full w-full flex-col items-center overflow-y-hidden pt-4 pb-14 md:pb-0">
      <div className="absolute top-2 left-4">
        <ArrowBack
          path={`/dashboard/${testName}`}
          label={width >= 768 && 'Gasket overview'}
        />
      </div>
      <span className="w-11/12 select-none border-b border-[#292B2F] pb-5 text-center font-heading text-2xl font-light md:text-4xl">
        {gasketParams?.name}
      </span>
      <div className="flex h-full w-11/12 flex-col items-center pt-6 md:max-w-5xl md:pt-10">
        <Stepper
          length={
            gasketParams.inspectionType === 'Accumulation'
              ? 3
              : 2
          }
          forceHorizontal>
          <Stepper.Step
            title="Step 1"
            label="Calibration"
            icon={
              <MdDriveFileRenameOutline className="h-full w-full" />
            }>
            <CalibrationStep
              params={gasketParams}
              user={user}
              testName={testName}
              state={calibration}
              setState={setCalibration}
              id={gasket}
            />
          </Stepper.Step>
          <Stepper.Step
            title="Step 2"
            label={
              gasketParams.inspectionType === 'Accumulation'
                ? 'Reading 1'
                : 'Testing'
            }
            icon={<FlangeIcon className="h-full w-full" />}>
            <TestingStep
              params={gasketParams}
              state={testing}
              setState={setTesting}
              testName={testName}
              setTestCounter={setTestCounter}
              calibration={calibration}
              user={user}
            />
          </Stepper.Step>
          {gasketParams.inspectionType ===
          'Accumulation' ? (
            <Stepper.Step
              title="Step 3"
              label={
                gasketParams.inspectionType ===
                'Accumulation'
                  ? 'Reading 2'
                  : 'Testing'
              }
              icon={
                <FlangeIcon className="h-full w-full" />
              }>
              <TestingStep
                params={gasketParams}
                state={testing}
                testName={testName}
                setState={setTesting}
                calibration={calibration}
                isSecondReading
                testCounter={testCounter}
                setTestCounter={setTestCounter}
                user={user}
              />
            </Stepper.Step>
          ) : (
            <></>
          )}
        </Stepper>
      </div>
    </div>
  );
};

export default GasketTest;
