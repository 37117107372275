const pdfDownloaded = async testName => {
  const response = await fetch(
    `${
      process.env.REACT_APP_DOMAIN
    }/api/test/pdf-downloaded?${new URLSearchParams({
      name: encodeURIComponent(testName),
    })}`,
    {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
    }
  );

  if (response.status === 200) {
    const json = await response.json();

    return json;
  } else return null;
};

export default pdfDownloaded;
