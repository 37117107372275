import { toast } from 'react-toastify';

const setPdfDownloaded = async (body, name) => {
  const response = await fetch(
    `${process.env.REACT_APP_DOMAIN}/api/gaskets/pdf-downloaded`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      mode: 'cors',
      body: JSON.stringify(body),
    }
  );

  if (response.status !== 200) {
    const json = await response.json();

    return toast.error(json?.error);
  }

  const blob = await response.blob();
  const file = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = file;
  link.download = `${body.testName} - ${name}.pdf`;
  link.click();
};

export default setPdfDownloaded;
